<template>
  <transition name="fade" mode="out-in">
    <div v-if="!showDetails">
      <card card-body-classes="table-full-width">
        <h4 slot="header" class="card-title">Grupo de Clientes</h4>
        <div>
          <div class="row" style="float: right">
            <span style="margin: 0 10pt">
              <base-input>
                <el-input type="search" prefix-icon="el-icon-search" placeholder="Nome"
                  v-model="Filters.name"></el-input>
              </base-input>
            </span>
            <span style="margin: 0 10pt">
              <base-input>
                <el-select class="select-primary mb-3 pagination-select" v-model="Filters.type" placeholder="Per page">
                  <el-option class="select-primary" v-for="item in Filters.typeOptions" :key="item.name"
                    :label="item.name" :value="item.value"></el-option>
                </el-select>
              </base-input>
            </span>
            <span style="margin: 0 10pt">
              <button class="custom-btn-filter" @click="FilterGroupCode()">
                Filtrar
              </button>
            </span>
            <span style="margin: 0 10pt">
              <button class="custom-btn-filter" @click="GetGroupCode()">
                Limpar filtro
              </button>
            </span>
            <span style="margin: 0 10pt" v-if="checkFunctionality">
              <button class="custom-btn-add" @click="AddNew()"
                v-if="getUserPermission('14_c')">Novo</button>
            </span>
          </div>
          <svg v-if="showSpinner" class="spinner-lg" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
            <circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30" />
          </svg>
          <el-table :data="queriedData">
            <el-table-column v-for="column in tableColumns" :key="column.label" :min-width="column.minWidth"
              :prop="column.prop" :label="column.label" align="center"></el-table-column>
            <el-table-column :min-width="50" align="right" label="Ações">
              <div slot-scope="props">
                <base-button class="like !important btn-link" type="primary" size="sm" icon
                  @click="ShowDetails(props.row)" v-if="getUserPermission('14_c')">
                  <i class="material-icons">mode_edit_outline</i>
                </base-button>
                <base-button class="edit btn-link" type="danger" size="sm" icon @click="Delete(props.row)"
                  v-if="getUserPermission('14_c')">
                  <i class="material-icons">delete_outline</i>
                </base-button>
              </div>
            </el-table-column>
          </el-table>
        </div>
        <div slot="footer" class="
            col-12
            d-flex
            justify-content-center justify-content-between
            flex-wrap
          ">
          <div class="col-5"></div>
          <div class="col-2">
            <label>
              Exibindo
              <span class="primary-text">{{ from + 1 }}</span> -
              <span class="primary-text">{{ to }}</span> de
              <span class="primary-text">{{ total }}</span> registros
            </label>
          </div>
          <div class="col-3">
            <base-pagination class="pagination-no-border" v-model="pagination.currentPage"
              :per-page="pagination.perPage" :total="total"></base-pagination>
          </div>
          <div class="col-2">
            <el-select class="select-primary mb-3 pagination-select" style="width: 100px !important"
              v-model="pagination.perPage" placeholder="Per page">
              <el-option class="select-primary" v-for="item in pagination.perPageOptions" :key="item" :label="item"
                :value="item">
              </el-option>
            </el-select>
          </div>
        </div>
      </card>
    </div>

    <div v-else class="row">
      <div class="col-12">
        <h4 slot="header" class="card-title">
          <base-button class="like !important btn-link" type="primary" size="sm" icon @click="HideDetails()">
            <i class="material-icons">arrow_back</i> </base-button>Cadastro Grupo de Cliente
        </h4>
      </div>
      <div class="col-4">
        <card type="tasks" class="stacked-form">
          <h4 class="card-title">UF</h4>
          <div class="col-12 table-full-width table-responsive">
            <el-select class="select-primary teste" v-model="GroupCode.UF" v-bind:disabled="toDisabled === '1'"
              v-on:change="SetNewSelectedOption($event)">
              <el-option :name="option.label" v-for="option in selects.options" :value="option.value"
                :label="option.label" :key="option.label"></el-option>
            </el-select>
          </div>
          <h4 class="card-title">Código do Grupo de Cliente CPF</h4>
          <div class="col-12 table-full-width table-responsive">
            <base-input>
              <el-input-number :controls="false" placeholder="Informe o código do grupo para CPF"
                v-model="GroupCode.CPF"></el-input-number>
            </base-input>
          </div>
          <h4 class="card-title">Código do Grupo de Cliente CNPJ</h4>
          <div class="col-12 table-full-width table-responsive">
            <base-input>
              <el-input-number :controls="false" placeholder="Informe o código do grupo para CNPJ"
                v-model="GroupCode.CNPJ"></el-input-number>
            </base-input>
          </div>
          <h4 class="card-title">Código do Grupo de Cliente para CNPJ com Inscrição Estadual</h4>
          <div class="col-12 table-full-width table-responsive">
            <base-input>
              <el-input-number min="0" maxlength="20" :controls="false"
                placeholder="Informe o código do grupo para CNPJ Inscrição Estadual"
                v-model="GroupCode.CNPJ_IE"></el-input-number>
            </base-input>
          </div>
        </card>
      </div>
      <div class="col-lg-12 col-sm-12">
        <UpdateLog :dataLog="dataLog" />
      </div>
      <div class="col-12">
        <div class="
            d-flex
            justify-content-center justify-content-between
            flex-wrap
          ">
          <div></div>
          <div>
            <button class="custom-btn-save" @click="Save()">Salvar</button>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import planMixin from "src/mixins/planMixin.js";
import swal from "sweetalert2";
import { BasePagination, BaseRadio } from "src/components";
import vueDropdown from "vue-dynamic-dropdown";
import Inputmask from "inputmask";
import { mapState } from 'vuex';
import UpdateLog from "../../components/UpdateLog.vue";

export default {
  mixins: [planMixin],
  components: {
    BaseRadio,
    BasePagination,
    vueDropdown,
    Inputmask,
    UpdateLog
  },
  computed: {
    ...mapState(['realm']),
    queriedData() {
      let result = this.tableData;
      if (this.searchedData.length > 0) {
        result = this.searchedData;
      }
      if (!Array.isArray(result)) {
        return [result];
      }
      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.searchedData.length > 0
        ? this.searchedData.length
        : this.tableData.length;
    },
  },
  data() {
    return {
      dataLog: [],
      showDetails: false,
      selectedEcomm: "",
      toDisabled: "0",
      uf: "",

      Filters: {
        type: null,
        name: null,
        typeOptions: [
          { name: "Filtrar por...", value: null },
          { name: "UF", value: "UF" },
          { name: "Id", value: "Id" },
        ],
      },

      tableColumns: [
        {
          prop: "Id",
          label: "Id",
          minWidth: 40,
        },
        {
          prop: "UF",
          label: "UF",
          minWidth: 40,
        },
        {
          prop: "CPF",
          label: "Cód. do Grupo de Cliente CPF",
          minWidth: 100,
        },
        {
          prop: "CNPJ",
          label: "Cód. do Grupo de Cliente CNPJ",
          minWidth: 100,
        },
        {
          prop: "CNPJ_IE",
          label: "Cód. do Grupo de Cliente para CNPJ/IE",
          minWidth: 100,
        },
      ],
      pagination: {
        perPage: 10,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0,
      },

      selects: {
        uf: null,
        options: [
          { value: null, label: "Selecione a UF" },
          { value: "AC" },
          { value: "AL" },
          { value: "AP" },
          { value: "AM" },
          { value: "BA" },
          { value: "CE" },
          { value: "DF" },
          { value: "ES" },
          { value: "GO" },
          { value: "MA" },
          { value: "MT" },
          { value: "MS" },
          { value: "MG" },
          { value: "PA" },
          { value: "PB" },
          { value: "PR" },
          { value: "PE" },
          { value: "PI" },
          { value: "RJ" },
          { value: "RN" },
          { value: "RO" },
          { value: "RR" },
          { value: "SC" },
          { value: "SP" },
          { value: "SE" },
          { value: "TO" },
          { value: "RS" },
        ],
      },

      tableData: [],
      searchedData: [],

      showSpinner: false,
      response: null,

      GroupCode: null,

      erpTable: [],
      paymentConditionTable: [],

      toInsert: true,
      showSpinner1: false,
      showSpinner2: false,

      checkFunctionality: true,
    };
  },
  async mounted() {
    this.selectedEcomm = this.$store.state.ecommerce.Name;
    this.GetGroupCode();

    // await this.getPlan();
    // await this.getFunctionalities(this.isPlanControlActive);
  },
  methods: {
    //---- On load ---------------------------------------------------------
    GetGroupCode: async function () {
      this.showSpinner = true;
      let response = await this.$bahngleis.get(
        "GroupCodeBusinessPartner/getAll?ecommerceName=" + this.selectedEcomm
      );

      this.Filters.name = null;
      this.Filters.type = null;
      this.tableData = response.data;
      this.showSpinner = false;
    },
    //---- Actions ---------------------------------------------------------
    FilterGroupCode: async function () {
      if (this.Filters.name == null || this.Filters.type == null) {
        this.ShowWarningNotification("Por favor, informe ambos os filtros");
      } else {
        this.showSpinner = true;
        this.response = await this.$bahngleis.get(
          "GroupCodeBusinessPartner/getByUF?ecommerceName=" +
          this.selectedEcomm +
          "&" +
          this.Filters.type +
          "=" +
          this.Filters.name
        );

        this.tableData = await this.response.data;
        this.showSpinner = false;
      }
    },

    SetNewSelectedOption() {
      this.selects.uf = this.GroupCode.UF;
    },

    ShowDetails: async function (groupCode) {
      this.GroupCode = groupCode;
      this.toInsert = false;
      this.toDisabled = "1";
      this.showDetails = !this.showDetails;

      let responseLog = await this.$bahngleis.get(
        "/LogAction/getlogsofentity?identity=" +
        groupCode.Id +
        "&entity=GroupCode"
      );

      this.dataLog = await responseLog.data;
    },

    AddNew: function () {
      this.GroupCode = {
        Id: 0,
        UF: null,
        CPF: null,
        CNPJ: null,
        CNPJ_IE: null,
        ecommerceName: null,
      };
      this.toInsert = true;
      this.toDisabled = "0";
      this.showDetails = !this.showDetails;
    },

    Delete: function (groupCode) {
      var vm = this;
      this.GroupCode = groupCode;

      swal({
        title: "Gostaria de excluir o registro?",
        text: "Esse processo não poderá ser revertido.",
        showCancelButton: true,
        confirmButtonClass: "btn btn-success btn-fill",
        cancelButtonClass: "btn btn-danger btn-fill",
        cancelButtonText: "Não",
        confirmButtonText: "Sim",
      }).then((result) => {
        if (result.value) {
          vm.Del();
        }
      });
    },

    HideDetails: function () {
      this.GroupCode = null;
      this.showDetails = !this.showDetails;
    },
    Save: function () {
      if (this.GroupCode.UF == null) {
        this.ShowWarningNotification(
          "Por favor, informe o <b>UF</b> do usuário"
        );
      } else {
        var vm = this;
        if (vm.toInsert) {
          vm.Insert();
        } else {
          vm.Update();
        }
      }
    },
    Insert: function () {
      var vm = this;
      this.GroupCode.ecommerceName = this.selectedEcomm;
      this.$bahngleis
        .post("GroupCodeBusinessPartner/post", this.GroupCode, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then(function () {
          swal({
            title: "Sucesso",
            text: "O cadastro foi inserido com sucesso",
            type: "success",
            confirmButtonClass: "btn btn-success btn-fill",
            buttonsStyling: false,
          });
          vm.showDetails = false;
          vm.GetGroupCode();
        })
        .catch(function (response) {
          let message = "Ocorreu um erro ao inserir o cadastro";

          if (
            response.response.status === 400 &&
            response.response.data.cod === 1
          ) {
            message = "UF já cadastrada";
          }
          swal({
            title: "Atenção",
            text: message,
            type: "error",
            confirmButtonClass: "btn btn-success btn-fill",
            buttonsStyling: false,
          });
        });
    },
    Del: function () {
      this.GroupCode.ecommerceName = this.selectedEcomm;
      let vm = this;
      this.$bahngleis
        .delete("GroupCodeBusinessPartner/delete/" + this.GroupCode.Id)
        .then(function (response) {
          if (response.status === 200) {
            swal({
              title: "Sucesso",
              text: "O cadastro foi excluido com sucesso",
              type: "success",
              confirmButtonClass: "btn btn-success btn-fill",
              buttonsStyling: false,
            });
            vm.showDetails = false;
            vm.GetGroupCode();
          } else {
            swal({
              title: "Atenção",
              text: "Ocorreu um erro ao excluir o cadastro",
              type: "error",
              confirmButtonClass: "btn btn-success btn-fill",
              buttonsStyling: false,
            });
          }
        });
    },
    Update: function () {
      var vm = this;
      this.$bahngleis
        .put(
          "GroupCodeBusinessPartner/put/" + this.GroupCode.Id,
          this.GroupCode,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then(function () {
          swal({
            title: "Sucesso",
            text: "O cadastro foi atualizado",
            type: "success",
            confirmButtonClass: "btn btn-success btn-fill",
            buttonsStyling: false,
          });
          vm.showDetails = false;
        })
        .catch(function () {
          swal({
            title: "Atenção",
            text: "Ocorreu um erro ao atualizar o cadastro",
            type: "error",
            confirmButtonClass: "btn btn-success btn-fill",
            buttonsStyling: false,
          });
        });
    },

    //---- Auxiliaries ---------------------------------------------------------
    ShowWarningNotification: function (message) {
      this.$notify({
        message: message,
        timeout: 3000,
        icon: "tim-icons icon-bell-55",
        horizontalAlign: "right",
        verticalAlign: "top",
        type: "danger",
      });
      return;
    },
    getUserPermission(role) {
      return this.$keycloak.hasRealmRole(role);
    }
  },
};
</script>
<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.9s;
}

.fade-enter,
.fade-leave-to

/* .fade-leave-active em versões anteriores a 2.1.8 */
  {
  opacity: 0;
}

.select-primary {
  min-width: 100%;
  color: #00adb7;
}

.el-input {
  position: relative;
  font-size: 14px;
  display: inline-block;
  width: 100%;
}
</style>
