<template>
    <div class="row">
        <div class="col-6">
            <card type="tasks">
                <h4>Sincronizações Pendentes de Envio</h4>
                <el-table :data="syncsPending">
                    <el-table-column label="Atualização de Estoque" align="center" prop="Stock" />
                    <el-table-column label="Atualização de Preço" align="center" prop="Price" />
                    <el-table-column label="Atualização de Status" align="center" prop="Status" />
                </el-table>
            </card>
        </div>
        <div class="col-6">
            <card type="tasks">
                <h4>Sincronização Manual</h4>
                <div class="d-flex">
                    <h5 class="border-right mr-2">
                        <icon class="mr-2" src="material-symbols:family-history" /><strong>Estoque</strong><br>
                        <base-button type="primary" @click="$router.push({ name: 'SyncStock' })" class="ml-2 mr-2"
                            size="sm">Sincronizar</base-button>
                    </h5>
                    <h5 class="border-right mr-2">
                        <icon class="mr-2" src="material-symbols:currency-exchange-rounded" /><strong>Preço</strong><br>
                        <base-button type="primary" @click="$router.push({ name: 'SyncPrice' })" class="ml-2 mr-2"
                            size="sm">Sincronizar</base-button>
                    </h5>
                    <h5 class="border-right mr-2">
                        <icon class="mr-2" src="carbon:delivery-parcel" /><strong>Pedidos</strong><br>
                        <base-button type="primary" @click="$router.push({ name: 'SyncOrder' })" class="ml-2 mr-2"
                            size="sm">Sincronizar</base-button>
                    </h5>
                    <h5 class="mr-2">
                        <icon class="mr-2" src="majesticons:status-online" /><strong>Status do Pedido</strong><br>
                        <base-button type="primary" @click="$router.push({ name: 'SyncStatus' })" class="ml-2 mr-2"
                            size="sm">Sincronizar</base-button>
                    </h5>
                </div>
            </card>
        </div>
        <div class="col-12">
            <card type="tasks">
                <h4>Últimas Sincronizações</h4>
                <div class="col-12 table-full-width table-responsive">
                    <el-table :data="recurringJobHistories">
                        <el-table-column label="Id" align="center" prop="Id" />
                        <el-table-column label="Inicio" align="center" prop="StartedAt" :formatter="dateFormatter" />
                        <el-table-column label="Ação" align="center" prop="Type" />
                        <el-table-column label="Enviados" align="center" prop="ItemsSentQuantity" />
                        <el-table-column label="Termino" align="center" prop="FinishedAt" :formatter="dateFormatter" />
                        <el-table-column label="Confirmados" align="center" prop="ItemsConfirmedQuantity" />
                    </el-table>
                </div>
            </card>
        </div>
        <div class="col-12">
            <card type="tasks">
                <UpdateLog :dataLog="dataLog" />
            </card>
        </div>
        <router-view></router-view>
    </div>
</template>
<script>
import { mapActions, mapState } from 'vuex';
import moment from "moment";
import UpdateLog from "@/components/UpdateLog.vue";

export default {
    components: {
        UpdateLog
    },
    data() {
        return {
            recurringJobHistories: [],
            syncsPending: [],
            dataLog: []
        }
    },
    computed: {
        ...mapState('commandbridge', ['logs'])
    },
    methods: {
        ...mapActions('commandbridge', ['getRecurringJobHistories', 'getSyncsPending', 'getLogCustomEvent', 'getLogs']),
        dateFormatter(row, col, value, index) {
            return moment.utc(value).format("DD/MM/YYYY HH:mm:ss");
        },
    },
    async mounted() {
        await this.getRecurringJobHistories().then(response => {
            this.recurringJobHistories = response.data.RecurringJobHistories;
        });
        await this.getSyncsPending().then(response => {
            this.syncsPending.push(response.data);
        });
        await this.getLogCustomEvent(['ManualCapturePrice', 'ManualCaptureStock', 'ManualCaptureOrder']).then(() => {
            this.dataLog = this.logs;
        });
    },
}
</script>
<style lang="">

</style>
