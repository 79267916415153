<template>
    <card type="tasks" class="stacked-form">
        <div class="d-flex justify-content-between">
            <h4 class="card-title">Opções</h4>
            <base-button type="primary" small class="btn-next" @click="showModalOption">
                Adicionar
            </base-button>
        </div>
        <div class="col-12">
            <el-table :data="listOptions">
                <el-table-column label="Tipo de documento" align="center">
                    <div slot-scope="props">
                        {{ getCostCenterType(props.row.TypeCostCenter) }}
                    </div>
                </el-table-column>
                <el-table-column v-for="column in tableColumnsOption" :key="column.label" :min-width="column.minWidth"
                    :prop="column.prop" :label="column.label" align="center">
                </el-table-column>
                <el-table-column :min-width="135" align="right" label="Ações">
                    <div slot-scope="props">
                        <base-button class="like !important btn-link" type="danger" size="sm" icon
                            @click="removeOption(props.row)">
                            <i class="material-icons">delete_outline</i>
                        </base-button>
                    </div>
                </el-table-column>
            </el-table>
        </div>
        <CostCenterConfigurationOptionModal :show="showModal" @evtAddedOption="saveOption"
            @evtClose="closeModalOption" />
</card>
</template>

<script>

import constants from '@/util/constants'
import CostCenterConfigurationOptionModal from './CostCenterConfigurationOptionModal'

export default {
    name: "CostCenterConfigurationOption",
    components: {
        CostCenterConfigurationOptionModal
    },
    props: {
        options: {
            type: Array
        }
    },
    data() {
        return {
            listOptions: [],
            showModal: false,
            tableColumnsOption: [
                {
                    prop: "ValueCostCenter",
                    label: "Centro de custo"
                }
            ],
        }
    },
    methods: {
        showModalOption() {
            this.showModal = true;
        },
        saveOption(model) {
            this.listOptions.push({ ...model });
            this.showModal = false;
        },
        closeModalOption() {
            this.showModal = false;
        },
        removeOption(row) {
            var index = this.listOptions
                            .findIndex(x=>x.TypeCostCenter == row.TypeCostCenter && 
                                        x.ValueCostCenter == row.ValueCostCenter);

            this.listOptions.splice(index, 1);
        },
        value() {
            return this.listOptions;
        },
        getCostCenterType(value) {
            return constants.costCenter.find(a => a.value === value).description;
        },
    },
    created() {
        this.listOptions = this.options;
    },
    watch: {
        options(newVal) {
            this.listOptions = newVal;
        }
    }
}
</script>