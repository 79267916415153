<template>
  <transition name="fade" mode="out-in">
    <card card-body-classes="table-full-width">
      <h4 slot="header" class="card-title">Notificação de e-mail</h4>
      <div class="row" style="float: right">
        <span style="margin: 0 10pt">
          <span class="mr-2">
            <el-button class="custom-btn-filter" @click="history()">
              Histórico
            </el-button>
          </span>
            <el-button class="custom-btn-add" @click="addNew" v-if="getUserPermission('26_c')">
              Novo
            </el-button>
        </span>
      </div>
      <div>
        <el-table :data="notificationEmails.Items">
          <el-table-column label="Notificação" align="center">
            <div slot-scope="props">
              {{ getNotificationEmailType(props.row.NotificationType) }}
            </div>
          </el-table-column>
          <el-table-column label="Frequência" align="center">
            <div slot-scope="props">
              {{ getFrequency(props.row.Frequency) }}
            </div>
          </el-table-column>
          <el-table-column label="Estado" align="center" min-width="40">
            <div slot-scope="props">
              <div v-bind:class="ChecksNotificationStatus(props.row.Active)">
                {{ props.row.Active == true ? "Ativo" : "Inativo" }}
              </div>
            </div>
          </el-table-column>
          <el-table-column align="center" label="Ações">
            <div slot-scope="props">
              <el-tooltip content="Editar" effect="light" :open-delay="300" placement="top">
                <base-button class="like !important btn-link" type="primary" size="sm" icon @click="detail(props.row.Id)"
                  v-if="getUserPermission('26_c')">
                  <i class="material-icons">mode_edit_outline</i>
                </base-button>
              </el-tooltip>
              <el-tooltip content="Deletar" effect="light" :open-delay="300" placement="top">
                <base-button class="edit btn-link" type="danger" size="sm" icon @click="remove(props.row.Id)"
                  v-if="getUserPermission('26_c')">
                  <i class="material-icons">delete_outline</i>
                </base-button>
              </el-tooltip>
              <el-tooltip content="Ver histórico de notificação" effect="light" :open-delay="300" placement="top">
                <base-button class="edit btn-link" type="primary" size="sm" icon @click="showModalLog(props.row.Id)">
                  <i class="material-icons">assignment</i>
                </base-button>
              </el-tooltip>
              <NotificationEmailLog :show="showModalNotificationLog" :typeForm="props.row.Id" @evtClose="closeModal" />
            </div>
          </el-table-column>
        </el-table>
      </div>
    </card>
  </transition>
</template>
<script>
import swal from "sweetalert2";
import constants from '../../../../util/constants';
import { mapActions, mapState } from 'vuex';
import { BasePagination, BaseRadio } from "src/components";
import NotificationEmailLog from "../components/NotificationEmailLog.vue";

export default {
  components: {
    BaseRadio,
    BasePagination,
    NotificationEmailLog
  },
  data() {
    return {
      pagination: {
        perPage: 5,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0,
        notificationEmailId: 0
      },
      showModalNotificationLog: false
    };
  },
  computed: {
    ...mapState('notificationEmail', ['notificationEmails']),
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.notificationEmails.length
    }

  },
  methods: {
    ...mapActions('notificationEmail', ['getNotificationEmails', 'removeNotificationEmail', 'getNotificationEmailLogs']),
    async loadNotificationEmails() {
      let params = Object.assign(this.pagination);
      await this.getNotificationEmails(params);
    },
    async loadNotificationEmailLog(id) {
      this.pagination.notificationEmailId = id;
      let params = Object.assign(this.pagination);
      await this.getNotificationEmailLogs(params);
    },
    detail(id) {
      this.$router.push({ name: "NotificationEmailDetail", query: { id: id } })
    },
    history() {
      this.$router.push({ name: "NotificationEmailLog" })
    },
    addNew() {
      this.$router.push({ name: "NotificationEmailCreate" })
    },
    remove(id) {
      swal({
        title: "Gostaria de excluir o registro?",
        text: "Esse processo não poderá ser revertido.",
        showCancelButton: true,
        confirmButtonClass: "btn btn-success btn-fill",
        cancelButtonClass: "btn btn-danger btn-fill",
        cancelButtonText: "Não",
        confirmButtonText: "Sim",
      }).then(async (result) => {
        if (result.value) {
          await this.delete(id)
          await this.loadNotificationEmails();
        }
      });
    },
    async delete(id) {
      try {
        await this.removeNotificationEmail(id);
        this.$showSuccess("O cadastro foi excluido com sucesso");
      } catch (error) {
        this.$showError("Ocorreu um erro ao excluir o cadastro");
      }
    },
    getNotificationEmailType(key) {
      return constants.NotificationEmailType.find(a => a.Key === key).Label;
    },
    getFrequency(key) {
      if (!key) return '-';
      return constants.Frequency.find(a => a.Key === key).Label;
    },
    ChecksNotificationStatus: function (status) {
      const statusParams = {
        true: "statusActive",
        false: "statusInactive",
      };

      return `status ${statusParams[status]}` || "status statusDefault";
    },
    showModalLog(id) {
      this.loadNotificationEmailLog(id);
      this.showModalNotificationLog = true;
    },
    closeModal() {
      this.showModalNotificationLog = false;
    },
    getUserPermission(role) {
      return this.$keycloak.hasRealmRole(role);
    }
  },
  async mounted() {
    this.loadNotificationEmails();
  },
};
</script>
<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.9s;
}

.fade-enter,
.fade-leave-to

/* .fade-leave-active em versões anteriores a 2.1.8 */
  {
  opacity: 0;
}

textarea {
  background: transparent !important;
  border-color: #2b3553 !important;
}

.el-textarea__inner:focus {
  outline: 0;
  border-color: #00ADB7 !important;
}

.el-textarea__inner:hover {
  border-color: #00ADB7 !important;
}
</style>
