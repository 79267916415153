<template>
    <div class="row">
        <div class="col-12">
            <h4 slot="header" class="card-title">
                <base-button class="like !important btn-link" type="primary" size="sm" icon @click="$router.go(-1)">
                    <i class="material-icons">arrow_back</i>
                </base-button> Editar Usuário
            </h4>
        </div>
        <div class="col-12">
            <UserForm ref="form" />
        </div>
        <div class="col-lg-12 col-sm-12">
            <UpdateLog :dataLog="logs" />
        </div>
        <div class="col-12">
            <div class="d-flex justify-content-center justify-content-between flex-wrap">
                <div></div>
                <div>
                    <button class="custom-btn-save" @click="update">Atualizar</button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { mapActions, mapMutations, mapState } from "vuex";
import UserForm from "../components/UserForm.vue";
import UpdateLog from "../../../components/UpdateLog.vue";
import { EventBus } from "@/eventBus";

export default {
    components: {
        UserForm,
        UpdateLog
    },
    data() {
        return {
            param: {
                id: null,
                realm: null
            },
            logs : []
        }
    },
    computed: {
        ...mapState('user', ['users', 'userPermissions'])
    },
    methods: {
        ...mapActions('user', ['updateUser', 'getUserLogs']),
        ...mapMutations('user', ['SET_USER_CURRENT']),
        async getUserCurrent() {
            let userId = this.$route.query.id;
            let user = this.users.find(a => a.id === userId);
            this.SET_USER_CURRENT(user);
            EventBus.$emit("loadUser");
            await this.getUserLogs(user.id)
            .then(response => this.logs = response.data)
            .catch(error => this.$showError(error))
        },
        async update() {
            try {
                let form = this.$refs.form;

                if (!form.validate())
                    return;

                const data = {
                    param: {
                        id: this.$route.query.id,
                        realm: this.$route.query.realm
                    },
                    formData: form.getValue()
                }

                await this.updateUser(data);
                this.$showSuccess("O cadastro foi atualizado com sucesso");
                this.$router.push({ name: 'User' });
            } catch (error) {
                this.$showError("Ocorreu um erro ao atualizar o cadastro");
            }
        }
    },
    async mounted() {
        await this.getUserCurrent();
    }
}
</script>
