<template>
  <transition name="fade" mode="out-in">
    <card card-body-classes="table-full-width">
      <h3 slot="header" class="card-title">Cadastrar organização</h3>
      <OrganizationForm :typeForm="typeForm" ref="form" />
      <div slot="footer" class="col-12 d-flex justify-content-end flex-wrap">
        <div class="col-4">
          <button class="custom-btn-add m-1" @click="$router.push({ name: 'Organizations' })">Voltar</button>
          <button class="custom-btn-save m-1" @click="save">Salvar</button>
        </div>
      </div>
    </card>
  </transition>
</template>
<script>
import OrganizationForm from "../components/OrganizationForm.vue";
import { mapActions, mapState } from "vuex";
import constants from "@/util/constants";

export default {
  components: {
    OrganizationForm,
  },
  data() {
    return {
      typeForm: constants.typeForm.create,
    };
  },
  computed: {
    ...mapState(['ecommerce']),
  },
  methods: {
    ...mapActions("organization", ["saveOrganization", "insertECompayment"]),
    async save() {
      let form = this.$refs.form;

      if (!form.validate())
        return;

      let payload = form.getValue();
      await this.saveOrganization(payload).then(() => {
        this.$showSuccess("Organização cadastrada com sucesso");

        this.$router.push({ name: "Organizations" });
      }).catch(error => {
        if (error.response.data)
          this.$showError(error.response.data);
        else
          this.$showError(error);
      });
    },
  },
};
</script>
<style>
.el-dropdown-menu__item:focus,
.el-dropdown-menu__item:not(.is-disabled):hover {
  background-color: #00ADB7;
  color: #ffffff !important;
}

.el-button-group>.el-button.is-active,
.el-button-group>.el-button:active,
.el-button-group>.el-button:focus,
.el-button-group>.el-button:hover {
  color: #ffffff !important;
  background-color: #00ADB7 !important;
}

.el-button {
  display: inline-block;
  color: #ffffff;
  background-color: #00ADB7;
}
</style>
