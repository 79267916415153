<template>
    <div class="row">
        <div class="col-12">
            <card type="tasks" class="stacked-form">
                <div class="col-12">
                    <div class="row">
                        <div class="col-3">
                            <base-input>
                                <label>Nome:</label>
                                <el-select v-model="addon.Name" placeholder="Selecione" :disabled="disabledField">
                                    <el-option v-for="item in addonNameOptions" :key="item.value" :label="item.label"
                                        :value="item.value">
                                    </el-option>
                                </el-select>
                            </base-input>
                        </div>
                        <div class="col-3">
                            <base-input>
                                <label>Status:</label><br />
                                <toggle-button :labels="{ checked: 'ON', unchecked: 'OFF' }" color="#7dd7dc" input
                                    type="checkbox" v-model="addon.Active" :sync="true" />
                            </base-input>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <base-input>
                                <label>Caminho da consulta:</label>
                                <el-input type="textarea" placeholder="Informar o caminho da consulta"
                                    v-model="addon.QueryPath" :autosize="{ minRows: 4, maxRows: 10 }">
                                </el-input>
                            </base-input>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <base-input>
                                <label>Consulta:</label>
                                <el-input type="textarea" placeholder="Informar a consulta" v-model="addon.Query"
                                    :autosize="{ minRows: 4, maxRows: 10 }">
                                </el-input>
                            </base-input>
                        </div>
                    </div>
                    <div class="col-3">
                        <base-input>
                            <label>Consulta Alternativa:</label><br />
                            <toggle-button :labels="{ checked: 'ON', unchecked: 'OFF' }" color="#7dd7dc" input
                                type="checkbox" v-model="addon.ActiveQueryAlternative" :sync="true" />
                        </base-input>
                    </div>
                    <div v-if="addon.ActiveQueryAlternative">
                        <div class="row">
                            <div class="col-12">
                                <base-input>
                                    <label>Caminho da consulta alternativa:</label>
                                    <el-input type="textarea" placeholder="Informar o caminho da consulta alternativa"
                                        v-model="addon.QueryPathAlternative" :autosize="{ minRows: 4, maxRows: 10 }">
                                    </el-input>
                                </base-input>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <base-input>
                                    <label>Consulta alternativa:</label>
                                    <el-input type="textarea" placeholder="Informar a consulta alternativa"
                                        v-model="addon.QueryAlternative" :autosize="{ minRows: 4, maxRows: 10 }">
                                    </el-input>
                                </base-input>
                            </div>
                        </div>
                    </div>
                </div>
            </card>
        </div>
    </div>
</template>
<script>
import { mapState } from 'vuex';
import { EventBus } from "@/eventBus";
import constants from '@/util/constants';

export default {
    computed: {
        ...mapState('addon', ['addonCurrent']),
        ...mapState(['ecommerce'])
    },
    data() {
        return {
            addon: {
                Name: null,
                Active: false,
                Query: "",
                QueryPath: "",
                ActiveQueryAlternative: false,
                QueryAlternative: "",
                QueryPathAlternative: ""
            },
            statusOptions: [
                { value: false, label: 'Inativo' },
                { value: true, label: 'Ativo' }
            ],
            addonNameOptions: [],
            disabledField: false
        }
    },

    methods: {
        save() {
            this.$emit("save");
        },
        validate() {
            if (this.$isNullOrEmpty(this.addon.Name)) {
                this.$showError("Nome obrigatório.");
                return false;
            } else if (this.$isNullOrEmpty(this.addon.Query) && this.addon.Name.toUpperCase() !== 'INVENT') {
                this.$showError("Consulta obrigatório.");
                return false;
            } else if (this.$isNullOrEmpty(this.addon.QueryPath) && this.addon.Name.toUpperCase() !== 'INVENT') {
                this.$showError("Caminho da consulta obrigatório.");
                return false;
            } else if (this.$isNullOrEmpty(this.addon.QueryAlternative) && this.addon.ActiveQueryAlternative && this.addon.Name.toUpperCase() !== 'INVENT') {
                this.$showError("Consulta alternativa obrigatório.")
            } else if (this.$isNullOrEmpty(this.addon.QueryPathAlternative) && this.addon.ActiveQueryAlternative && this.addon.Name.toUpperCase() !== 'INVENT') {
                this.$showError("Caminho da consulta alternativa obrigatório..")
            } else
                return true;
        },
        getValue() {
            return this.addon;
        },
        load() {
            this.addon = this.addonCurrent;
            if (this.addonCurrent) {
                this.disabledField = true;
            }
        },
        getAddonNameOptions() {
            this.addonNameOptions = constants.AddonName;
        },
    },
    async mounted() {
        EventBus.$on("loadAddon", () => this.load());
        this.getAddonNameOptions();
    },
}
</script>
<style>
.el-switch.is-checked .el-switch__core {
    border-color: #00ADB7 !important;
    background-color: #00ADB7 !important;
}

.el-switch__label.is-active {
    color: #00ADB7 !important;
}

.el-select-dropdown__item.selected {
    color: #00ADB7;
    font-weight: 700;
}

.form-group .el-select .el-input.is-disabled .el-input__inner {
    cursor: not-allowed !important;
}
</style>
