103 lines (96 sloc)  2.82 KB
<template>
  <div class="example-simple">
     <h4 id="example-title" class="card-title">Importação Manual</h4>
    <div class="upload">

      <card  id="divDownload" card-body-classes="table-full-width">
        <h4 slot="header" class="card-title">Produtos</h4>

        <div>
          <p>Gere um arquivo CSV contendo todos os produtos e links das imagens que estão armazenadas no Google Drive. Esse processo pode levar até 10 minutos para ser concluído.</p>
        </div>

        <div class="upload">
      <ul>
        <li v-for="(file) in files" :key="file.id">
          <span>{{file.name}}</span> -
          <span>{{file.size | formatSize}}</span>
        </li>
      </ul>
      <base-input class="col-2">
                <el-select
                  class="select-primary mb-3 pagination-select"
                  v-model="acao"
                  placeholder="Ação"
                >
                  <el-option
                    class="select-primary"
                    v-for="(item, index) in statusList"
                    :key="index"
                    :label="item.name"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </base-input>
      <div class="example-btn">
        <file-upload
          class="btn btn-primary"
          post-action="/upload/post"
          extensions="xlsx"
          accept=".xlsx"
          :multiple="false"
          :size="1024 * 1024 * 10"
          v-model="files"
          @input-filter="inputFilter"
          @input-file="inputFile"
          ref="upload">
          <i class="fa fa-plus"></i>
          Selecionar Arquivo
        </file-upload>
         <button type="button" class="btn btn-success" v-if="getUserPermission('21_c') && (!$refs.upload || !$refs.upload.active)" @click.prevent="$refs.upload.active = true">
           <i class="fa fa-arrow-up" aria-hidden="true"></i>
           Começar Importação
         </button>
         <button type="button" class="btn btn-danger"  v-else @click.prevent="$refs.upload.active = false">
           <i class="fa fa-stop" aria-hidden="true"></i>
           Parar importação
         </button>

         <a class="ml-2 btn btn-info" :href="templateImport" download>
          <i class="fa fa-arrow-down" aria-hidden="true"></i> Baixar Template
         </a>

      </div>


    </div>
      </card>
      </div>

  </div>

</template>
<style>
.example-simple label.btn {
  margin-bottom: 0;
  margin-right: 1rem;
}
.btn-template {
      background: #00ADB7;
    background-image: -webkit-gradient(linear, right top, left bottom, from(#00ADB7), color-stop(#7DD7DC), to(#00ADB7));
    background-image: linear-gradient(to bottom left, #00ADB7, #7DD7DC, #00ADB7);
    background-size: 210% 210%;
    background-position: top right;
    background-color: #00ADB7;
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    -webkit-box-shadow: none;
    box-shadow: none;
    color: #ffffff;
}
</style>

<script>
import FileUpload from 'vue-upload-component'
import swal from "sweetalert2";

export default {
  components: {
    FileUpload,
  },
  data() {
    return {
        files: [],
        addFile: true,
        // [Select.name]: Select,
        [Option.name]: Option,
        acao:{},
        statusList: [{ name: "Imagens", value: "IMAGENS" }],
        searchedData: [],
        templateImport : require('@/assets/template-xls/Template-Atualizacao-manual.xlsx')
    }
  },
  methods: {
    inputFilter(newFile, oldFile, prevent) {
      if (newFile && !oldFile) {
        // Before adding a file
        // Filter system files or hide files

        if (!/\.(xlsx)$/i.test(newFile.name)) {
          return prevent()
        }
      }
    },
    inputFile: async function(newFile, oldFile) {
      var vm = this;
      if (newFile && !oldFile) {
        // add
        this.addFile = true;
      }
      if (newFile && oldFile) {
        // update
          let formData = new FormData();
          formData.append("file", this.$refs.upload.files[0].file);

          let config = {
            headers: {
              "Content-Type": "multipart/form-data"
            }
          };
          if (this.addFile)
          {
          this.addFile = false;
          this.$bahngleis.defaults.timeout = 60000;

          await this.$bahngleis
            .post("ManualImporte/Import?ecommerceName=" + this.$store.state.ecommerce.Name + "&type=" + this.acao  ,  formData, config)
            .then(async function(response) {
              if (response.status === 200) {
                swal({
                        title: "Sucesso",
                        text: "O arquivo foi importado corretamente",
                        type: "success",
                        confirmButtonClass: "btn btn-success btn-fill",
                        buttonsStyling: false
                        });
                        vm.addFile = true;
                        vm.$refs.upload.files[0].file.success = true;

              }
              else {
                  swal({
                      title: "Atenção",
                      text: "Ocorreu um erro ao importar o arquivo. " + response.message,
                      type: "error",
                      confirmButtonClass: "btn btn-success btn-fill",
                      buttonsStyling: false
                    });
              }
            });
          }
      }
      if (!newFile && oldFile) {
        // remove
      }
    },
    getUserPermission(role) {
      return this.$keycloak.hasRealmRole(role);
    }
  }
}
</script>
