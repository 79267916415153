<template>
  <div class="modal fade" id="documentModal" data-backdrop="static" tabindex="-1" role="dialog"
       aria-labelledby="documentModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-xl" role="document">
      <div class="modal-content bg-color-default">
        <div class="modal-header">
          <h4 slot="header" id="documentModalLabel">
            Gerar Arquivos DTW - Notas de Devolução
          </h4>
          <button type="button" class="close" @click="closeModal">
            <span aria-hidden="true" class="text-white">
              <i class="tim-icons icon-simple-remove"></i>
            </span>
          </button>
        </div>
        <div class="modal-body">
          <card type="tasks" class="stacked-form">
            <base-input>
              <label>Email(s):
                <el-tooltip content="Para adicionar mais de um e-mail separar por (;)." effect="light" :open-delay="100"
                            placement="top">
                  <i class="material-icons ml-1 mb-1 icon-custom">info_outline</i>
                </el-tooltip>
              </label>
              <el-input v-model="returnInvoices.Email" placeholder="Informe o(s) E-mail(s)" />
            </base-input>
            <file-upload class="btn btn-primary btn-sm" extensions="xlsx" accept=".xlsx" :multiple="false"
                         :size="1024 * 1024 * 10" v-model="files" @input-filter="inputFilter" @input-file="inputFile($event)" ref="upload">
              <i class="fa fa-plus"></i>
              Selecionar Arquivo
            </file-upload>
            <div class="d-flex justify-content-between">
              <base-button type="primary" small class="ml-auto" @click="buildReturnInvoicesFromFile()">
                Gerar Arquivos
              </base-button>
            </div>
          </card>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapState } from 'vuex';
import Modal from '@/components/Modal';
import FileUpload from 'vue-upload-component';

export default {
  components: {
    Modal,
    FileUpload
  },
  data() {
    return {
      files: [],
      formDataFile: new FormData(),
      returnInvoices: {
        Email: null
      }
    }
  },
  computed: {
    ...mapState(['ecommerce'])
  },
  methods: {
    ...mapActions('orders', ['buildReturnInvoices']),
    inputFilter(newFile, oldFile, prevent) {
      if (newFile && !oldFile) {
        if (!/\.(xlsx)$/i.test(newFile.name)) {
          return prevent()
        }
      }
    },
    inputFile: async function (newFile) {
      this.formDataFile.append("file", newFile.file);
    },
    closeModal() {
      $(".modal-backdrop").hide();
      this.$router.go(-1);
    },
    closeModalOnOutsideClick(event) {
      if (document.body.classList.contains('modal-open') && !event.target.closest('.modal-dialog')) {
        this.closeModal();
      }
    },
    validate() {
      if (this.$isNullOrEmpty(this.returnInvoices.Email)) {
        this.$showError("Email(s) obrigatório.");
        return false;
      } else if (this.$isNullOrEmpty(this.files)) {
        this.$showError("Arquivo deve ser selecionado.");
        return false;
      }
      else
        return true;
    },
    async buildReturnInvoicesFromFile() {
      if (!this.validate())
        return;

      let params = Object.assign({ formData: this.formDataFile, email: this.returnInvoices.Email });

      await this
        .buildReturnInvoices(params)
        .then(() => {
          this.$showSuccess("Geração iniciada com sucesso.")
          this.files = [];
          this.closeModal();
        })
        .catch(error => {
          this.$showError(error)
        });
    }
  },
  async mounted() {
    setTimeout(() => {
      document.addEventListener('click', this.closeModalOnOutsideClick);
      jQuery("#documentModal").modal("show");
    }, 500);
  },

  beforeDestroy() {
    document.removeEventListener('click', this.closeModalOnOutsideClick);
  },
}
</script>
<style>
.modal-footer button {
  margin: 10;
  padding-left: 16px;
  padding-right: 16px;
  width: auto;
}

.el-button--primary.is-disabled,
.el-button--primary.is-disabled:active,
.el-button--primary.is-disabled:focus,
.el-button--primary.is-disabled:hover {
  color: #FFF;
  background-color: #7ed3d8;
  border-color: #7ed3d8;
}

.el-button:focus,
.el-button:hover {
  color: #FFF;
  border-color: #00ADB7;
  background-color: #00ADB7;
}

.el-button--primary {
  color: #FFF;
  background-color: #4eacb1;
  border-color: #4eacb1;
}

.el-switch.is-checked .el-switch__core {
  border-color: #00ADB7 !important;
  background-color: #00ADB7 !important;
}

.el-switch__label.is-active {
  color: #00ADB7 !important;
}

.el-select-dropdown__item.selected {
  color: #00ADB7;
  font-weight: 700;
}

.form-group .el-select .el-input.is-disabled .el-input__inner {
  cursor: not-allowed !important;
}

svg.iconify.iconify--mdi {
  cursor: pointer;
}
</style>
