<template>
    <el-dialog title="Sincronizar Estoque" :visible.sync="dialogVisible" width="30%" :before-close="handleClose">
        <div class="mb-2" v-if="!isVulcabras">
            <el-radio v-model="recurringJob" class="text-dark" label="sync-sap-stock">Captar estoque no ERP</el-radio><br>
            <el-radio v-model="recurringJob" class="text-dark" label="sync-stock">Enviar estoque ao ecommerce</el-radio>
        </div>
        <el-divider v-if="!isVulcabras"></el-divider>
        <div class="mb-2" v-if="!isVulcabras && getUserPermission('00_c')">
            <el-checkbox v-model="syncAll">Sincronizar todos SKUs</el-checkbox>
        </div>
        <el-divider v-if="!isVulcabras"></el-divider>
        <span v-if="!syncAll">Especifique o(s) SKU(s) - cada um por linha</span>
        <div class="d-flex" v-if="!syncAll">
            <el-input placeholder="SKUs" rows="5" type="textarea" v-model="number" :disabled="syncAll"></el-input>
        </div>
        <span slot="footer" class="dialog-footer">
            <el-button @click="handleClose">Cancelar</el-button>
            <el-button type="primary" @click="save">Sincronizar</el-button>
        </span>
    </el-dialog>
</template>
<script>
import Modal from '@/components/Modal'
import { mapState } from 'vuex';
export default {
    components: [
        Modal
    ],
    components: { Modal },
    data() {
        return {
            dialogVisible: false,
            number: null,
            skus: [],
            recurringJob: "sync-stock",
            title: "",
            isVulcabras: false,
            syncAll: false
        }
    },
    methods: {
        handleClose() {
            this.$router.go(-1);
        },
        clearNumber() {
            this.number = null;
        },
        getUserPermission(role) {
            return this.$keycloak.hasRealmRole(role);
        },
        save() {
            if (this.$isNullOrEmpty(this.number) && !this.syncAll) {
                this.$showError("Nenhum SKU especificado!");
                return;
            }

            if (!this.syncAll)
                this.skus = this.number.split("\n");

            this.$bahngleis.post(`RecurringJobs/${this.recurringJob}?ecommerceName=${this.$store.state.ecommerce.Name}&forceCapture=true`, this.skus);

            this.$showSuccess("Sincronização iniciada com sucesso!");

            this.handleClose();
        },
    },
    computed: {
        ...mapState(['realm'])
    },
    mounted() {
        if (this.realm === "vulcabras")
            this.isVulcabras = true;

        this.clearNumber();
        this.dialogVisible = true;
    },
    destroyed() {
        this.dialogVisible = false;
    },
}
</script>
