<template>
  <div class="row">
    <div class="col-6">
      <card type="tasks" class="stacked-form">
        <div class="col-12">
          <div class="row">
            <base-input>
              <label>Nome:</label>
              <el-input v-model="organization.Name" placeholder="Informe o nome" :disabled="disabledField" />
            </base-input>
          </div>
          <div class="row">
            <base-input>
              <label>Organização de Venda:</label>
              <el-input v-model="organization.SalesOrganization" placeholder="Informe a organização de venda" />
            </base-input>
          </div>
          <div class="row">
            <base-input>
              <label>Canal de Distribuição:</label>
              <el-input v-model="organization.DistributionChannel" placeholder="Informe o canal de distribuição" />
            </base-input>
          </div>
          <div class="row">
            <base-input>
              <label>Divisão:</label>
              <el-input v-model="organization.Division" placeholder="Informe a divisão" />
            </base-input>
          </div>
          <div class="row">
            <base-input>
              <label>Incluir no Parceiro de Negócio:</label><br />
              <toggle-button :labels="{ checked: 'ON', unchecked: 'OFF' }" color="#7dd7dc" input type="checkbox"
                v-model="organization.IncludeInBusinessPartner" :sync="true" />
            </base-input>
          </div>
          <div class="row">
            <base-input>
              <label>Sincronização de preço:</label><br />
              <toggle-button :labels="{ checked: 'ON', unchecked: 'OFF' }" color="#7dd7dc" input type="checkbox"
                v-model="organization.SyncPrice" :sync="true" />
            </base-input>
          </div>
        </div>
      </card>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import { EventBus } from "@/eventBus";

export default {
  computed: {
    ...mapState('organization', ['organizationCurrent']),
    ...mapState(['ecommerce']),
  },
  data() {
    return {
      organization: {
        EcommerceName: "",
        Name: "",
        SalesOrganization: "",
        DistributionChannel: "",
        Division: "",
        IncludeInBusinessPartner: false,
        SyncPrice: false
      },
      disabledField: false
    }
  },

  methods: {
    save() {
      this.$emit("save");
    },
    validate() {
      if (this.$isNullOrEmpty(this.organization.Name)) {
        this.$showError("Nome obrigatório.");
        return false;
      } else if (this.$isNullOrEmpty(this.organization.SalesOrganization)) {
        this.$showError("Organização de venda obrigatório.");
        return false;
      } else if (this.$isNullOrEmpty(this.organization.DistributionChannel)) {
        this.$showError("Canal de distribuição obrigatório.");
        return false;
      } else if (this.$isNullOrEmpty(this.organization.Division)) {
        this.$showError("Divisão obrigatório.");
        return false;
      } else
        return true;
    },
    getValue() {
      return this.organization;
    },
    load() {
      this.organization = this.organizationCurrent;
      if (this.organizationCurrent) {
        this.disabledField = true;
      }
    }
  },
  mounted() {
    EventBus.$on("loadOrganization", () => this.load());
  },
}
</script>
<style>
.el-switch.is-checked .el-switch__core {
  border-color: #00ADB7 !important;
  background-color: #00ADB7 !important;
}

.el-switch__label.is-active {
  color: #00ADB7 !important;
}

.el-select-dropdown__item.selected {
  color: #00ADB7;
  font-weight: 700;
}

.form-group .el-select .el-input.is-disabled .el-input__inner {
  cursor: not-allowed !important;
}
</style>
