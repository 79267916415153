<template>
    <div class="modal fade" id="logModal" data-backdrop="static" tabindex="-1" role="dialog" aria-labelledby="logModalLabel"
      aria-hidden="true">
      <div class="modal-dialog modal-xl" role="document">
        <div class="modal-content bg-color-default">
          <div class="modal-header">
            <h4 slot="header" id="logModalLabel">
              Logs de integração
            </h4>
            <button type="button" class="close" @click="closeModal">
              <span aria-hidden="true" class="text-white">
                <i class="tim-icons icon-simple-remove"></i>
              </span>
            </button>
          </div>
          <div class="modal-body">
            <div>
              <el-table v-loading="loading" element-loading-background="rgba(0, 0, 0, 0.0)" :data="logs" max-height="370px">
                <el-table-column label="Mensagem" min-width="500" prop="message"></el-table-column>
                <el-table-column label="Data" min-width="150" align="center" prop="dateTime">
                  <template slot-scope="scope">
                    {{ scope.row.dateTime | toDateTime }}
                  </template>
                </el-table-column>
                <el-table-column :min-width="100" label="Status">
                  <div slot-scope="props">
                    <div :class="getClass(props.row.status)">
                      {{ props.row.status | nameStatus }}
                    </div>
                  </div>
                </el-table-column>
              </el-table>
              <div class="float-right">
                <router-link :to="{
                  path: '/message',
                  query: { orderNumber: number },
                }" class="btn-link" type="primary"
                  onclick="jQuery('.modal-backdrop').removeClass('modal-backdrop'); return true;">
                  <base-button class="btn-link" type="primary">
                    Ver todos >
                  </base-button>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  <script>
  import Modal from '@/components/Modal'
  
  export default {
    components: {
      Modal
    },
    data() {
      return {
        show: false,
        logs: [],
        id: null,
        number: null,
        loading: true
      }
    },
    methods: {
      closeModal() {
        $(".modal-backdrop").hide();
        this.$router.go(-1);
      },
      closeModalOnOutsideClick(event) {
        if (document.body.classList.contains('modal-open') && !event.target.closest('.modal-dialog')) {
          this.closeModal();
        }
      },
      async getLogs() {
        this.loading = true;
        await this.$bahngleisWithoutLoading
          .get(`/logs/last-five?orderNumber=${this.number}&InternalLog=false&PageSize=5`)
          .then(res => {
            this.logs = res.data.Items;
          })
          .catch(error => { this.$showError(error.response.data) })
  
        this.loading = false;
      },
      goToLogs() {
        this.$router.push({ name: "FulfillmentDetail", params: { id: this.id } });
      },
      getClass(status) {
        const statusParams = {
          Error: "statusError",
          Started: "statusComplete",
          Success: "statusIntegrated",
          Canceled: "statusCanceled",
          Delete: "statusCanceled",
          Information: "statusProcessing",
        };
  
        return statusParams[status] ? `status ${statusParams[status]}` : "status statusDefault";
      },
    },
    mounted() {
      this.id = this.$route.query.id;
      this.number = this.$route.query.number;
      this.getLogs();
      setTimeout(() => {
        document.addEventListener('click', this.closeModalOnOutsideClick);
        jQuery("#logModal").modal("show");
      }, 500);
    },
    beforeDestroy() {
      document.removeEventListener('click', this.closeModalOnOutsideClick);
    },
  }
  </script>

  