<template>
    <transition name="fade" mode="out-in">
        <card card-body-classes="table-full-width">
            <h4 slot="header" class="card-title">
                <base-button class="like !important btn-link" type="primary" size="sm" icon @click="changeReturn()">
                    <i class="material-icons">arrow_back</i>
                </base-button>
                Histórico Notificação de e-mail
            </h4>
            <div class="row" style="float: right">
                <span style="margin: 0 10pt">
                    <base-input>
                        <el-date-picker placeholder="Data inicial" v-model="filters.from" format="dd/MM/yyyy">
                        </el-date-picker>
                    </base-input>
                </span>
                <span style="margin: 0 10pt">
                    <base-input>
                        <el-date-picker placeholder="Data final" v-model="filters.to" format="dd/MM/yyyy">
                        </el-date-picker>
                    </base-input>
                </span>
                <span style="margin: 0 10pt">
                    <el-button class="custom-btn-filter" @click="loadNotificationEmailLog">
                        Filtrar
                    </el-button>
                </span>
                <span style="margin: 0 10pt">
                    <button class="custom-btn-filter" @click="clearFilter">
                        Limpar filtro
                    </button>
                </span>
            </div>
            <div>
                <el-table :data="notificationEmailLogs.Items">
                    <el-table-column prop="NotificationEmail.Subject" label="Assunto" align="left" :min-width="150">
                    </el-table-column>
                    <el-table-column prop="Date" label="Data" align="center">
                        <div slot-scope="props">
                            {{ getDate(props.row.Date) }}
                        </div>
                    </el-table-column>
                    <el-table-column label="Hora" align="center">
                        <div slot-scope="props">
                            {{ getHour(props.row.Date) }}
                        </div>
                    </el-table-column>
                </el-table>
            </div>
            <div slot="footer" class="col-12 d-flex justify-content-center justify-content-between flex-wrap">
                <div class="col-5"></div>
                <div class="col-2">
                    <label>
                        Exibindo
                        <span class="primary-text">{{ from + 1 }}</span> -
                        <span class="primary-text">{{ to }}</span> de
                        <span class="primary-text">{{ total }}</span> registros
                    </label>
                </div>
                <div class="col-3">
                    <base-pagination class="pagination-no-border" v-model="pagination.currentPage"
                        :per-page="pagination.perPage" :total="total" @input="loadNotificationEmailLog"></base-pagination>
                </div>
                <div class="col-2">
                    <el-select class="select-primary mb-3 pagination-select" style="width: 100px !important"
                        v-model="pagination.perPage" placeholder="Per page">
                        <el-option class="select-primary" v-for="item in pagination.perPageOptions" :key="item"
                            :label="item" :value="item"></el-option>
                    </el-select>
                </div>
            </div>
        </card>
    </transition>
</template>
<script>
import constants from '../../../../util/constants';
import { mapActions, mapState } from 'vuex';
import { BasePagination, BaseRadio } from "src/components";
import NotificationEmailLog from "../components/NotificationEmailLog.vue";

import moment from "moment";

export default {
    components: {
        BaseRadio,
        BasePagination,
        NotificationEmailLog
    },
    data() {
        return {
            filters: {
                from: null,
                to: null
            },
            pagination: {
                perPage: 5,
                currentPage: 1,
                perPageOptions: [5, 10, 25, 50],
                total: 0,
                notificationEmailId: 0
            },
            showModalNotificationLog: false
        };
    },
    computed: {
        ...mapState('notificationEmail', ['notificationEmailLogs']),
        to() {
            let highBound = this.from + this.pagination.perPage;
            if (this.total < highBound) {
                highBound = this.total;
            }
            return highBound;
        },
        from() {
            return this.pagination.perPage * (this.pagination.currentPage - 1);
        },
        total() {
            return this.notificationEmailLogs.Total;
        }
    },
    methods: {
        ...mapActions('notificationEmail', ['getNotificationEmailLogEcommerce']),

        async loadNotificationEmailLog() {

            if (this.filters.from != "" && this.filters.from != null) {
                this.filters.from = moment.utc(this.filters.from).format("YYYY-MM-DD") + " 00:00:00";
            }
            if (this.filters.to != "" && this.filters.to != null) {
                this.filters.to = moment.utc(this.filters.to).format("YYYY-MM-DD") + " 23:59:59";
            }

            let params = Object.assign(this.filters, this.pagination);
            await this.getNotificationEmailLogEcommerce(params);
        },
        clearFilter() {
            this.filters.from = null;
            this.filters.to = null;
            this.loadNotificationEmailLog();
        },
        getNotificationEmailType(key) {
            return constants.NotificationEmailType.find(a => a.Key === key).Label;
        },
        getDate(date) {
            if (!date) return '-';
            return moment.utc(String(date)).format('DD/MM/yyyy')
        },
        getHour(date) {
            if (!date) return '-';
            return moment.utc(String(date)).format('HH:mm')
        },
        changeReturn() {
            this.$router.push({ name: "NotificationEmail" })
        }
    },
    async mounted() {
        this.loadNotificationEmailLog();
    },
};
</script>
<style>
.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.9s;
}

.fade-enter,
.fade-leave-to

/* .fade-leave-active em versões anteriores a 2.1.8 */
    {
    opacity: 0;
}

textarea {
    background: transparent !important;
    border-color: #2b3553 !important;
}

.el-textarea__inner:focus {
    outline: 0;
    border-color: #00ADB7 !important;
}

.el-textarea__inner:hover {
    border-color: #00ADB7 !important;
}
</style>
  