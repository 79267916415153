<template>
  <transition name="fade" mode="out-in">
    <div v-if="!showDetails">
      <card card-body-classes="table-full-width">
        <h4 slot="header" class="card-title">Marca</h4>
        <div>
          <div class="row" style="float: right">
            <span style="margin: 0 10pt">
              <base-input>
                <el-input type="search" prefix-icon="el-icon-search" placeholder="Nome"
                  v-model="Filters.name"></el-input>
              </base-input>
            </span>
            <span style="margin: 0 10pt">
              <base-input>
                <el-select class="select-primary mb-3 pagination-select" v-model="Filters.type" placeholder="Per page">
                  <el-option class="select-primary" v-for="item in Filters.typeOptions" :key="item.name"
                    :label="item.name" :value="item.value"></el-option>
                </el-select>
              </base-input>
            </span>
            <span style="margin: 0 10pt">
              <button class="custom-btn-filter" @click="FilterBrands()">
                Filtrar
              </button>
            </span>
            <span style="margin: 0 10pt">
              <button class="custom-btn-filter" @click="GetBrands()">
                Limpar filtro
              </button>
            </span>
            <div>
              <span style="margin: 0 10pt">
                <button class="custom-btn-add" @click="AddNew()" v-if="getUserPermission('03_c')">Novo</button>
              </span>
            </div>
          </div>
          <svg v-if="showSpinner" class="spinner-lg" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
            <circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30" />
          </svg>
          <el-table :data="tableData">
            <el-table-column v-for="column in tableColumns" :key="column.label" :min-width="column.minWidth"
              :prop="column.prop" :label="column.label" align="center"></el-table-column>
            <el-table-column :min-width="135" align="right" label="Ações">
              <div slot-scope="props">
                <base-button class="like !important btn-link" type="primary" size="sm" icon
                  @click="ShowDetails(props.row)" v-if="getUserPermission('03_c')">
                  <i class="material-icons">mode_edit_outline</i>
                </base-button>
                <base-button class="edit btn-link" type="danger" size="sm" icon @click="Delete(props.row)"
                  v-if="getUserPermission('03_c')">
                  <i class="material-icons">delete_outline</i>
                </base-button>
              </div>
            </el-table-column>
          </el-table>
        </div>
        <div slot="footer" class="
            col-12
            row d-flex
            justify-content-center justify-content-between
            flex-wrap
          ">
          <div class="col-lg-4 col-sm-12" style="display: flex; align-items: center; justify-content: center">
            <label>
              Exibindo
              <span class="primary-text">{{ from + 1 }}</span> -
              <span class="primary-text">{{ to }}</span> de
              <span class="primary-text">{{ total }}</span> registros
            </label>
          </div>
          <div class="col-lg-4 col-sm-12" style="display: flex; align-items: center; justify-content: center">
            <base-pagination class="pagination-no-border" v-model="pagination.currentPage"
              :per-page="pagination.perPage" :total="total" @input="FilterBrands"></base-pagination>
          </div>
          <div class="col-lg-4 col-sm-12" style="display: flex; align-items: center; justify-content: center">
            <el-select class="select-primary mb-3 pagination-select" style="width: 100px !important"
              v-model="pagination.perPage" placeholder="Per page">
              <el-option class="select-primary" v-for="(item, index) in pagination.perPageOptions" :key="index"
                :label="item" :value="item"></el-option>
            </el-select>
          </div>
        </div>
      </card>
    </div>
    <div v-else class="row">
      <div class="col-12">
        <h4 slot="header" class="card-title">
          <base-button class="like !important btn-link" type="primary" size="sm" icon @click="HideDetails()">
            <i class="material-icons">arrow_back</i> </base-button>Marca
        </h4>
      </div>
      <div class="col-sm-5">
        <card type="tasks" class="stacked-form">
          <base-input class="col-12 table-full-width table-responsive" label="ID da Marca no SAP"
            placeholder="Informar o ID SAP" type="number" min="0" :required="true"
            v-model="Brand.SapManufacturerCode" />
          <base-input class="col-12 table-full-width table-responsive" label="ID do Ecommerce"
            placeholder="Informar o ID do Ecommerce" type="number" min="0" :required="true"
            v-model="Brand.EcommerceId" />
          <base-input class="col-12 table-full-width table-responsive" label="Nome da Marca"
            placeholder="Informar o Nome da Marca" :required="true" v-model="Brand.Name" />
          <base-input class="col-12 table-full-width table-responsive" label="Informar a Sigla"
            placeholder="Informar a Sigla" :required="true" v-model="Brand.Initials" />
          <base-input class="col-12 table-full-width table-responsive" label="Posição do estoque"
            placeholder="Informar a Posição do Estoque" :required="true" v-model="Brand.StockPosition" />
          <div class="
            d-flex
            justify-content-end">
            <div class="m-0 mr-3 p-0">
              <button class="custom-btn-save" @click="Save()">Salvar</button>
            </div>
          </div>
        </card>
      </div>
      <div v-if="Brand.Id > 0" class="col-lg-12 col-sm-12">
        <UpdateLog :dataLog="dataLog" />
      </div>
    </div>
  </transition>
</template>
<script>
import swal from "sweetalert2";
import { BasePagination, BaseRadio } from "src/components";
import UpdateLog from "../../components/UpdateLog.vue";

export default {
  components: {
    BaseRadio,
    BasePagination,
    UpdateLog
  },
  computed: {
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.pagination.total;
    },
  },
  data() {
    return {
      dataLog: [],
      showDetails: false,
      ecommerce: "",
      selectedEcomm: "",
      toDisabled: "0",

      Filters: {
        type: null,
        name: null,
        typeOptions: [
          { name: "Filtrar por...", value: null },
          { name: "Nome da marca", value: "Name" },
          { name: "Sigla", value: "Initials" },
          { name: "ID da marca no SAP", value: "SapManufacturerCode" },
          { name: "Posição do estoque", value: "StockPosition" },
        ],
      },

      tableColumns: [
        {
          prop: "Id",
          label: "Id",
          minWidth: 100,
        },
        {
          prop: "Name",
          label: "Nome da Marca",
          minWidth: 100,
        },
        {
          prop: "Initials",
          label: "Sigla",
          minWidth: 100,
        },
        {
          prop: "SapManufacturerCode",
          label: "Id da Marca no Sap",
          minWidth: 100,
        },
        {
          prop: "StockPosition",
          label: "Posição do estoque",
          minWidth: 100,
        },
      ],
      pagination: {
        perPage: 5,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0,
      },

      tableData: [],

      showSpinner: false,
      response: null,

      Brand: null,

      erpTable: [],
      paymentConditionTable: [],

      toInsert: true,
      showSpinner1: false,
      showSpinner2: false,
    };
  },
  async mounted() {
    this.selectedEcomm = this.$store.state.ecommerce.Name;
    this.GetBrands();
  },
  methods: {
    //---- On load ---------------------------------------------------------
    GetBrands: async function () {
      this.showSpinner = true;
      let response = await this.$bahngleis.get(
        "Brand/GetAllWithFilter?ecommerceName=" + this.selectedEcomm +
        `&CurrentPage=${this.pagination.currentPage}&PerPage=${this.pagination.perPage}`
      ).then(result => {
        this.tableData = result.data.Data;
        this.showSpinner = false;
        this.Filters.name = null;
        this.Filters.type = null;
        this.pagination.total = result.data.Total;
      });
    },

    //---- Actions ---------------------------------------------------------
    FilterBrands: async function (response) {
      if ((this.Filters.name != null && this.Filters.name != '') && this.Filters.type === null) {
        this.ShowWarningNotification("Por favor, informe ambos os filtros");
      } else {
        this.showSpinner = true;
        this.response = await this.$bahngleis.get(
          "Brand/GetAllWithFilter?ecommerceName=" +
          this.selectedEcomm +
          "&" +
          this.Filters.type +
          "=" +
          this.Filters.name +
          `&CurrentPage=${this.pagination.currentPage}&PerPage=${this.pagination.perPage}`
        ).then(result => {
          if (result.status == 200) this.tableData = result.data.Data;
          this.pagination.total = result.data.Total;
        });

        this.showSpinner = false;
      }
    },

    ShowDetails: async function (Brand) {
      this.Brand = Brand;
      this.toInsert = false;
      this.toDisabled = "1";
      this.showDetails = !this.showDetails;

      let responseLog = await this.$bahngleis.get(
        "/LogAction/getlogsofentity?identity=" +
        Brand.Id +
        "&entity=Brand"
      );

      this.dataLog = await responseLog.data;
    },

    AddNew: function () {
      this.Brand = {
        Id: 0,
        Name: null,
        Initials: null,
        SapManufacturerCode: null,
        StockPosition: null,
        EcommerceId: null,
        ecommerceName: null,
      };
      this.dataLog = [];
      this.toInsert = true;
      this.toDisabled = "0";
      this.showDetails = !this.showDetails;
    },

    Delete: function (Brand) {
      var vm = this;
      this.Brand = Brand;

      swal({
        title: "Gostaria de excluir o registro?",
        text: "Esse processo não poderá ser revertido.",
        showCancelButton: true,
        confirmButtonClass: "btn btn-success btn-fill",
        cancelButtonClass: "btn btn-danger btn-fill",
        cancelButtonText: "Não",
        confirmButtonText: "Sim",
      }).then((result) => {
        if (result.value) {
          var pos = this.tableData.indexOf(Brand);
          this.tableData.splice(pos, 1);
          vm.Del();
        }
      });
    },

    HideDetails: function () {
      this.Brand = null;
      this.showDetails = !this.showDetails;
    },
    Save() {
      this.validate
      if (!this.validate()) return;

      if (this.toInsert) {
        this.Insert();
      } else {
        this.Update();
      }
    },
    Insert: function () {
      this.Brand.ecommerceName = this.selectedEcomm;
      var vm = this;
      this.$bahngleis
        .post("Brand", this.Brand, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then(function () {
          swal({
            title: "Sucesso",
            text: "O cadastro foi inserido com sucesso",
            type: "success",
            confirmButtonClass: "btn btn-success btn-fill",
            buttonsStyling: false,
          });
          vm.showDetails = false;
          vm.GetBrands();
        })
        .catch(function (response) {
          let message = "Ocorreu um erro ao inserir o cadastro";
          if (
            response.response.status === 400
          ) {
            message = response.response.data;
          }
          swal({
            title: "Atenção",
            text: message,
            type: "error",
            confirmButtonClass: "btn btn-success btn-fill",
            buttonsStyling: false,
          });
        });
    },
    Del: function () {
      this.Brand.ecommerceName = this.selectedEcomm;
      this.$bahngleis
        .delete("Brand/" + this.Brand.Id)
        .then(function (response) {
          if (response.status === 200) {
            swal({
              title: "Sucesso",
              text: "O cadastro foi excluido com sucesso",
              type: "success",
              confirmButtonClass: "btn btn-success btn-fill",
              buttonsStyling: false,
            });
            vm.showDetails = false;
          } else {
            swal({
              title: "Atenção",
              text: "Ocorreu um erro ao excluir o cadastro",
              type: "error",
              confirmButtonClass: "btn btn-success btn-fill",
              buttonsStyling: false,
            });
          }
        });
    },

    Update: function () {
      var vm = this;
      this.$bahngleis
        .put("Brand/", this.Brand, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then(function () {
          swal({
            title: "Sucesso",
            text: "O cadastro foi atualizado",
            type: "success",
            confirmButtonClass: "btn btn-success btn-fill",
            buttonsStyling: false,
          });
          vm.showDetails = false;
        })
        .catch(function (response) {
          let message = "Ocorreu um erro ao atualizar o cadastro";
          if (
            response.response.status === 400
          ) {
            message = response.response.data.name;
          }
          swal({
            title: "Atenção",
            text: message,
            type: "error",
            confirmButtonClass: "btn btn-success btn-fill",
            buttonsStyling: false,
          });
        });
    },
    //---- Auxiliaries ---------------------------------------------------------
    ShowWarningNotification: function (message) {
      this.$notify({
        message: message,
        timeout: 3000,
        icon: "tim-icons icon-bell-55",
        horizontalAlign: "right",
        verticalAlign: "top",
        type: "danger",
      });
      return;
    },
    getUserPermission(role) {
      return this.$keycloak.hasRealmRole(role);
    },
    validate() {
      let errors = "";
      let valid = true;

      if (this.$isNullOrEmpty(this.Brand.SapManufacturerCode)) {
        errors += "- <b>ID da Marca no SAP</b> é obrigatório";
        valid = false;
      }
      if (this.$isNullOrEmpty(this.Brand.EcommerceId)) {
        errors += "<br>- <b>ID do Ecommerce</b> é obrigatório";
        valid = false;
      }
      if (this.$isNullOrEmpty(this.Brand.Name)) {
        errors += "<br>- <b>Descrição</b> é obrigatória";
        valid = false;
      }
      if (this.$isNullOrEmpty(this.Brand.Initials)) {
        errors += "<br>- <b>Sigla</b> é obrigatória";
        valid = false;
      }
      if (this.$isNullOrEmpty(this.Brand.StockPosition)) {
        errors += "<br>- <b>Posição do estoque</b> é obrigatório";
        valid = false;
      }

      if (!valid)
        this.$message({
          dangerouslyUseHTMLString: true,
          type: 'error',
          duration: 3000,
          message: errors
        });

      return valid;
    },
  },
};
</script>
<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.9s;
}

.fade-enter,
.fade-leave-to

/* .fade-leave-active em versões anteriores a 2.1.8 */
  {
  opacity: 0;
}
</style>
