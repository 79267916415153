<template>
    <div class="row">
        <div class="col-12">
            <h4 slot="header" class="card-title">
                <base-button class="like !important btn-link" type="primary" size="sm" icon @click="$router.go(-1)">
                    <i class="material-icons">arrow_back</i>
                </base-button> Detalhes Addon
            </h4>
        </div>
        <div class="col-12">
            <AddonForm ref="form" />
        </div>
        <div class="col-lg-12 col-sm-12">
            <UpdateLog :dataLog="logs" />
        </div>
        <div class="col-12">
            <div class="d-flex justify-content-center justify-content-between flex-wrap">
                <div></div>
                <div>
                    <button class="custom-btn-save" @click="update">Atualizar</button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { mapActions, mapMutations, mapState } from "vuex";
import AddonForm from "../components/AddonForm.vue";
import UpdateLog from "../../../../components/UpdateLog.vue";
import { EventBus } from "@/eventBus";

export default {
    components: {
        AddonForm,
        UpdateLog
    },
    computed: {
        ...mapState('addon', ['addons', 'logs'])
    },
    methods: {
        ...mapActions('addon', ['updateAddon', 'getLogAddon']),
        ...mapMutations('addon', ['SET_ADDON_CURRENT']),
        async getAddonCurrent() {
            let addonId = this.$route.query.id;
            let addon = this.addons.find(a => a.Id === addonId);
            this.SET_ADDON_CURRENT(addon);
            await this.getLogAddon(addonId);
            EventBus.$emit("loadAddon");
        },
        async update() {
            let form = this.$refs.form;
            if (!form.validate())
                return;

            await this.updateAddon(form.getValue()).then(() => {
                this.$showSuccess("O cadastro foi atualizado com sucesso");
                this.$router.push({ name: 'Addon' });
            })
                .catch(error => {
                    this.$showError(error)
                });
        }
    },
    async mounted() {
        await this.getAddonCurrent();
    }
}
</script>