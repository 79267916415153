<template>
    <div class="row">
        <div class="col-12">
            <h4 slot="header" class="card-title">
                <base-button class="like !important btn-link" type="primary" size="sm" icon @click="$router.go(-1)">
                    <i class="material-icons">arrow_back</i>
                </base-button> Editar Grupo
            </h4>
        </div>
        <div class="col-12">
            <GroupForm ref="form" />
        </div>
        <div class="col-lg-12 col-sm-12">
            <UpdateLog :dataLog="logs" />
        </div>
        <div class="col-12">
            <div class="d-flex justify-content-center justify-content-between flex-wrap">
                <div></div>
                <div>
                    <button class="custom-btn-save" @click="update">Atualizar</button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { mapActions, mapMutations, mapState } from "vuex";
import GroupForm from "../components/GroupForm.vue";
import UpdateLog from "../../../components/UpdateLog.vue";
import { EventBus } from "@/eventBus";

export default {
    components: {
        GroupForm,
        UpdateLog
    },
    data() {
        return {
            param: {
                id: null,
                realm: null
            }
        }
    },
    computed: {
        ...mapState('group', ['groups', 'logs'])
    },
    methods: {
        ...mapActions('group', ['updateGroup', 'getGroupLogs']),
        ...mapMutations('group', ['SET_GROUP_CURRENT']),
        async getGroupCurrent() {
            let groupId = this.$route.query.id;
            let group = this.groups.find(a => a.id === groupId);
            this.SET_GROUP_CURRENT(group);
            this.getGroupLogs(group.name).then(
                EventBus.$emit("loadGroup")
            );
        },
        async update() {
            try {
                let form = this.$refs.form;

                if (!form.validate())
                    return;

                const data = {
                    param: {
                        id: this.$route.query.id,
                        realm: this.$route.query.realm
                    },
                    formData: form.getValue()
                }

                await this.updateGroup(data);
                this.$showSuccess("O cadastro foi atualizado com sucesso");
                this.$router.push({ name: 'Group' });
            } catch (error) {
                this.$showError("Ocorreu um erro ao atualizar o cadastro");
            }
        }
    },
    mounted() {
        this.getGroupCurrent();
    }
}
</script>