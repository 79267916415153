<template>
  <div>
    <div class="row">
      <arrow-back
        :title="'Produto Variante'"
        :route-name="'ProductVariation'"
      ></arrow-back>
    </div>
    <card card-body-classes="table-full-width">
      <h4 slot="header" class="card-title">Anexar Produto Variante</h4>
      <div>
        <card>
          <blockquote class="blockquote mb-0">
            <base-label> Nome: {{ product.Name }}</base-label> -
            <base-label> SKU: {{ product.SKU }}</base-label>
          </blockquote>
        </card>
        <div class="row float-right" style="margin: 0 10pt">
          <button class="custom-btn-add" @click="configAttributes()">
            Atributos
          </button>
        </div>
        <el-table :data="queriedData" scope="scope">
          <el-table-column
            v-for="column in tableColumns"
            :key="column.label"
            :min-width="column.minWidth"
            :prop="column.prop"
            :label="column.label"
            :formatter="column.formatter"
            align="center"
          >
          </el-table-column>

          <el-table-column :min-width="'100px'" align="right" label="Ações">
            <div slot-scope="props">
              <div v-if="!props.row.IdParent">
                <base-button
                  class="like btn-link"
                  type="primary"
                  size="sm"
                  icon
                  @click="add(props.row)"
                >
                  <i class="tim-icons icon-simple-add"></i>
                </base-button>
              </div>
              <div v-else>
                <base-button
                  class="like btn-link"
                  type="primary"
                  size="sm"
                  icon
                  @click="remove(props.row)"
                >
                  <i class="tim-icons icon-simple-delete"></i>
                </base-button>
              </div>
            </div>
          </el-table-column>
        </el-table>
      </div>
      <div
        slot="footer"
        class="col-12 d-flex justify-content-center justify-content-between flex-wrap"
      >
        <div class="col-5"></div>
        <div class="col-2">
          <label>
            Exibindo
            <span class="primary-text">{{ from + 1 }}</span> -
            <span class="primary-text">{{ to }}</span> de
            <span class="primary-text">{{ total }}</span> registros
          </label>
        </div>
        <div class="col-3">
          <base-pagination
            class="pagination-no-border"
            v-model="pagination.currentPage"
            :per-page="pagination.perPage"
            :total="total"
          ></base-pagination>
        </div>
        <div class="col-2">
          <el-select
            class="select-primary mb-3 pagination-select"
            style="width: 100px !important"
            v-model="pagination.perPage"
            placeholder="Per page"
          >
            <el-option
              class="select-primary"
              v-for="item in pagination.perPageOptions"
              :key="item"
              :label="item"
              :value="item"
            ></el-option>
          </el-select>
        </div>
      </div>
    </card>
  </div>
</template>

<script>
import ArrowBack from "src/components/ArrowBack/ArrowBack";

import {
  //   BaseProgress,
  BaseSwitch,
  //   Slider,
  TagsInput,
} from "src/components/index";
import { BasePagination, Card } from "src/components";
import swal from "sweetalert2";

export default {
  components: {
    ArrowBack,
    BasePagination,
    Card,
  },
  computed: {
    queriedData() {
      let result = this.tableData;
      if (this.searchedData.length > 0) {
        result = this.searchedData;
      }
      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.searchedData.length > 0
        ? this.searchedData.length
        : this.tableData.length;
    },
  },
  data() {
    return {
      currentEcommerce: undefined,
      product: {
        Id: null,
        Name: null,
        SKU: null,
      },
      pagination: {
        perPage: 5,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0,
      },
      searchQuery: "",
      propsToSearch: ["name"],

      tableColumns: [
        {
          prop: "SKU",
          label: "Sku",
          minWidth: 100,
        },
        {
          prop: "Name",
          label: "Nome",
          minWidth: 100,
          formatter: function (row, column, cellValue, index) {
            return cellValue ? cellValue : "[S/N]";
          },
        },
      ],
      tableData: [],
      searchedData: [],
    };
  },
  methods: {
    configAttributes: function () {
      this.$router.push({
        name: "ProductVariationAttribute",
        params: { id: this.$route.params.id },
      });
    },
    onLoad: async function () {
      let vm = this;

      let productResponse = await this.$bahngleis.get(
        "ProductSync/" + this.$route.params.id,
        {
          params: { ecommerceName: this.currentEcommerce },
        }
      );
      this.product = productResponse.data;

      let response = await this.$bahngleis.get("ProductSync", {
        params: { ecommerceName: this.currentEcommerce },
      });
      response.data.forEach(function (data) {
        if (data.Id != vm.product.Id) {
          vm.tableData.push(data);
        }
      });
    },
    add: async function (row) {
      var obj = { ...row };
      obj.IdParent = this.product.Id;

      let productResponse = await this.$bahngleis.put(
        "ProductSync/" + row.Id,
        obj
      );
      row.IdParent = obj.IdParent;
    },
    remove: async function (row) {
      row.IdParent = null;

      let productResponse = await this.$bahngleis.put(
        "ProductSync/" + row.Id,
        row
      );
    },
  },
  mounted() {
    this.currentEcommerce = this.$store.state.ecommerce.Name;
    this.onLoad();
  },
};
</script>

<style>
</style> 