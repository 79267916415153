<template>
  <transition name="fade" mode="out-in">
    <div>
      <card card-body-classes="table-full-width">
        <div class="d-flex justify-content-between">
          <h3 slot="header" class="card-title">Editar Produto</h3>
          <div slot="header" class="d-flex justify-content-end flex-wrap">
            <div class="row" style="float: left; margin-left: 4px">
              <button class="custom-btn-add m-1" @click="$router.go(-1)">Voltar</button>
              <button @click="update" class="custom-btn-save m-1">Atualizar</button>
            </div>
          </div>
        </div>
        <el-divider></el-divider>
        <ProductForm :typeForm="typeForm" ref="form" />
      </card>
      <div class="table-full-width">
        <UpdateLog :dataLog="logs" />
      </div>
    </div>
  </transition>
</template>
<script>
import ProductForm from "@/pages/Product/components/ProductForm";
import constants from "@/util/constants";
import { mapActions, mapState } from "vuex";
import { EventBus } from "@/eventBus";
import UpdateLog from "../../../components/UpdateLog.vue";

export default {
  components: {
    ProductForm,
    UpdateLog
  },
  data() {
    return {
      typeForm: constants.typeForm.edition,
    };
  },
  computed: {
    ...mapState("product", ["logs"]),
  },
  methods: {
    ...mapActions("product", ["getProductById", "updateProduct", "getLogs"]),
    update() {
      let form = this.$refs.form;

      if (!form.isFormValid())
        return;

      let payload = form.prepareFormToSend();

      this.updateProduct(payload).then(async () => {
        this.$showSuccess("Produto atualizado com sucesso!");
        await this.getProduct();
      }).catch(error => {
        error.response && error.response.data ? this.$showError(error.response.data)
          : this.$showError(error)
      });
    },
    async getProduct() {
      let id = this.$route.params.id;

      if (!id)
        this.$router.go(-1);

      this.getLogs(id);

      await this.getProductById(id).then(() => {
        EventBus.$emit("ProductRegisterEdition");
      }).catch(error => this.$showError(error));
    }
  },
  async beforeMount() {
    await this.getProduct();
    EventBus.$on("update-product", () => this.update());
  },
};
</script>
<style scoped>
.btn-custom {
  width: 150px !important;
}
</style>
