<template>
  <transition name="fade" mode="out-in">
    <div>
      <card card-body-classes="table-full-width">
        <h4 slot="header" class="card-title">Logs de Integração</h4>
          <div class="row" >
            <div class="col-md-2">
              <base-input>
                <el-date-picker
                  type="date"
                  format="dd/MM/yyyy"
                  placeholder="Data inicial"
                  v-model="Filters.searchFrom"
                ></el-date-picker>
              </base-input>
            </div>

            <div class="col-md-2">
              <base-input>
                <el-date-picker
                  type="date"
                  format="dd/MM/yyyy"
                  placeholder="Data final"
                  v-model="Filters.searchTo"
                ></el-date-picker>
              </base-input>
            </div>

            <div class="col-md-2">
              <base-input>
                <el-input
                  type="search"
                  clearable
                  prefix-icon="el-icon-search"
                  placeholder="N° do pedido"
                  v-model="orderNumber"
                ></el-input>
              </base-input>
            </div>
            <div class="col-md-2">
              <base-input >
                <el-input
                  type="search"
                  clearable
                  prefix-icon="el-icon-search"
                  placeholder="Texto do status"
                  v-model="status"
                ></el-input>
              </base-input>
            </div>
            <div class="col-md-1.5">
              <button
                class="custom-btn-filter"
                @click="getMessage(orderNumber, status)">
                Filtrar
              </button>
            </div>
            <div class="col-md-1.5 ml-2">
              <button class="custom-btn-save" @click="exportExcel()">
                Exportar
              </button>
            </div>
          </div>
          <svg
            v-if="showSpinner"
            class="spinner-lg"
            viewBox="0 0 66 66"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle
              class="path"
              fill="none"
              stroke-width="6"
              stroke-linecap="round"
              cx="33"
              cy="33"
              r="30"
            />
          </svg>
          <el-table :data="queriedData" style="width: 100%">
            <el-table-column
              v-for="column in tableColumns"
              :key="column.label"
              :min-width="column.minWidth"
              :prop="column.prop"
              :label="column.label"
              :align="column.align ? column.align : 'center'"
            >
              <div v-if="column.prop == 'status'" slot-scope="props">
                <div v-bind:class="getStatusCSSClass(props.row.logType.name, props.row.status)">
                  {{ props.row.status }}
                </div>
              </div>
              <div v-else-if="column.prop == 'logType'">
                {{ props.row.logType.name }}
              </div>
              <div v-else-if="column.prop == 'dateTime'">
                {{ props.row.dateTime | toDateTime}}
              </div>
              <div v-else>
                {{ props.row[column.prop] }}
              </div>
            </el-table-column>
          </el-table>
        <div
          slot="footer"
          class="
            col-12
            d-flex
            justify-content-center justify-content-between
            flex-wrap
          ">
          <div class="col-5"></div>
          <div class="col-2">
            <label>
              Exibindo
              <span class="primary-text">{{ from + 1 }}</span> -
              <span class="primary-text">{{ to }}</span> de
              <span class="primary-text">{{ total }}</span> registros
            </label>
          </div>
          <div class="col-3">
            <base-pagination
              class="pagination-no-border"
              v-model="pagination.currentPage"
              :per-page="pagination.pageSize"
              :total="total"
              @input="getMessage(orderNumber, status)"
            ></base-pagination>
          </div>
          <div class="col-2">
            <el-select
              class="select-primary mb-3 pagination-select"
              style="width: 100px !important"
              v-model="pagination.pageSize"
              placeholder="Per page"
            >
              <el-option
                class="select-primary"
                v-for="item in paginationConfig.perPageOptions"
                :key="item"
                :label="item"
                :value="item"
              ></el-option>
            </el-select>
          </div>
        </div>
      </card>
    </div>
  </transition>
</template>
<script>
import { BasePagination } from "src/components";
import moment from "moment";
import (/* webpackChunkName: "filter" */ 'src/util/filters');

export default {
  components: {
    BasePagination,
  },
  computed: {
    queriedData() {
      return this.tableData;
    },
    to() {
      let highBound = this.from + this.pagination.pageSize;
      if (this.totalCount < highBound) {
        highBound = this.totalCount;
      }
      return highBound;
    },
    from() {
      return this.pagination.pageSize * (this.pagination.currentPage - 1);
    },
    total() {
      return this.pagination.totalCount;
    },
  },
  data() {
    return {
      orderNumber: this.$route.query.orderNumber || "",
      selectedEcomm: "",
      showSpinner: false,
      status: this.$route.query.status || "",

      Filters: {
        searchFrom: null,
        searchTo: null,
      },

      tableColumns: [
        {
          prop: "id",
          label: "Id",
          minWidth: 100,
        },
        {
          prop: "orderNumber",
          label: "Pedido",
          minWidth: 180,
        },
        {
          prop: "orderChannel",
          label: "Canal",
          minWidth: 150,
        },
        {
          prop: "logType",
          label: "Tipo de Evento",
          minWidth: 150,
        },
        {
          prop: "status",
          label: "Status",
          minWidth: 200,
        },
        {
          prop: "dateTime",
          label: "Data",
          minWidth: 180,
        },
        {
          prop: "message",
          label: "Mensagem",
          minWidth: 450,
          align: "left",
        },
      ],
      pagination: {
        currentPage: 1,
        hasNext: false,
        hasPrevious: false,
        pageSize: 5,
        totalCount: 1,
        totalPages: 1,
      },
      paginationConfig: {
        perPageOptions: [5, 10, 25, 50],
      },
      tableData: [],
      searchedData: [],
      response: null,
    };
  },
  mounted() {
    this.selectedEcomm = this.$store.state.ecommerce.Name;
    this.getMessage(this.orderNumber, this.status);
  },
  methods: {
    //---- On load ---------------------------------------------------------
    async exportExcel(orderNumber, status) {
      try {
        let queryString = `?Ecommerce=${this.selectedEcomm}`;

        if (orderNumber) queryString += `&orderNumber=${orderNumber}`;
        if (status) queryString += `&status=${status}`;

        if (this.Filters.searchFrom)
          queryString += `&MinDateTime=${moment(this.Filters.searchFrom).format(
            "YYYY-MM-DD"
          )} 00:00:00`;

        if (this.Filters.searchTo)
          queryString += `&MaxDateTime=${moment(this.Filters.searchTo).format(
            "YYYY-MM-DD"
          )} 23:59:59`;

        let response = await this.$bahngleis
          .get(`/logs/export${queryString}`,
            {
              responseType: "blob"
            }
          );
        this.downloadFile("LogIntegration.xlsx", response.data);

      } catch (error) {
        swal({
          title: "Atenção",
          text: "Ocorreu um erro ao exportar planilha",
          type: "error",
          confirmButtonClass: "btn btn-success btn-fill",
          buttonsStyling: false,
        });
        this.ShowWarningNotification(error);
      }
    },
    downloadFile(file, fileContent) {
      const url = window.URL.createObjectURL(new Blob([fileContent]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", file);
      document.body.appendChild(link);
      link.click();
      return;
    },
    getMessage: async function (orderNumber, status) {
      // this.showSpinner = true;

      let queryString = `?Ecommerce=${this.selectedEcomm}`;

      if (orderNumber) queryString += `&orderNumber=${orderNumber}`;
      if (status) queryString += `&status=${status}`;

      if (this.Filters.searchFrom)
        queryString += `&MinDateTime=${moment(this.Filters.searchFrom).format(
          "YYYY-MM-DD"
        )} 00:00:00`;

      if (this.Filters.searchTo)
        queryString += `&MaxDateTime=${moment(this.Filters.searchTo).format(
          "YYYY-MM-DD"
        )} 23:59:59`;

      let page = this.pagination.currentPage || 1;
      let pageSize = this.pagination.pageSize || 5;
      let internalLog = true;

      queryString += `&InternalLog=${internalLog}&PageNumber=${page}&PageSize=${pageSize}`;

      let vm = this;
      await vm.$bahngleisWithoutLoading
        .get(`/logs${queryString}`)
        .then(function (response) {
          vm.tableData = response.data;

          if (response.headers["x-pagination"] != null) {
            let pagination = JSON.parse(response.headers["x-pagination"]);
            vm.pagination = pagination;
          }
        });
    },
    //TODO: AJUSTAR ISSO, TEM COMO MELHORAR
    getStatusCSSClass: function (eventType, status) {
      if(eventType === "Customizado"){
        return "status statusCustom";
      }

      const statusParams = {
        Error: "statusError",
        Complete: "statusComplete",
        Integrated: "statusIntegrated",
        Canceled: "statusCanceled",
        Started: "statusIntegrated",
      };

      return statusParams[status]
        ? `status ${statusParams[status]}`
        : "status statusDefault";
    },
  },
};
</script>
<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.9s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
