<template>
    <div class="row">
        <div class="col-12">
            <h4 slot="header" class="card-title">
                <base-button class="like !important btn-link" type="primary" size="sm" icon @click="$router.go(-1)">
                    <i class="material-icons">arrow_back</i>
                </base-button> Cadastro de Usuário
            </h4>
        </div>
        <div class="col-12">
            <UserForm ref="form" />
        </div>
        <div class="col-12">
            <div class="d-flex justify-content-center justify-content-between flex-wrap">
                <div></div>
                <div>
                    <button class="custom-btn-save" @click="save">Salvar</button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { mapActions } from "vuex";
import UserForm from "../components/UserForm.vue";

export default {
    components: {
        UserForm
    },
    methods: {
        ...mapActions('user', ['createUser']),
        async save() {
            let form = this.$refs.form;
            if (!form.validate())
                return;

            const data = {
                param: {
                    realm: this.$route.query.realm
                },
                formData: form.getValue()
            }

            this.createUser(data)
                .then(() => {
                    this.$showSuccess("O cadastro foi inserido com sucesso");
                    this.$router.push({ name: 'User' });
                })
                .catch(error => { this.$showError(error) });
        }
    }
}
</script>