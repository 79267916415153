<template>
    <Modal :modalContentClasses="'card'" :showClose="false" :show="show" :minWidth="5350">
        <template v-slot:header>
            <h4 class="card-title">Histórico Envio de Email</h4>
            <el-tooltip content="Fechar" effect="light" :open-delay="300" placement="top">
                <base-button class="edit btn-link" type="primary" size="sm" icon @click="closeModal">
                    <i class="tim-icons icon-simple-remove"></i>
                </base-button>
            </el-tooltip>
        </template>
        <div>
            <el-table :data="notificationEmailLogs.Items">
                <el-table-column prop="NotificationEmail.Subject" label="Notificação" align="left" :min-width="150">
                </el-table-column>
                <el-table-column prop="Date" label="Data" align="center">
                    <div slot-scope="props">
                        {{ getDate(props.row.Date) }}
                    </div>
                </el-table-column>
                <el-table-column label="Hora" align="center">
                    <div slot-scope="props">
                        {{ getHour(props.row.Date) }}
                    </div>
                </el-table-column>
            </el-table>
        </div>
    </Modal>
</template>
<script>
import Modal from '@/components/Modal'
import { mapState, mapActions } from 'vuex';
import moment from "moment";

export default {
    props: {
        show: {
            type: Boolean,
            default: false
        }
    },
    components: {
        Modal
    },
    data() {
        return {
            pagination: {
                notificationEmailId: 0,
                perPage: 5,
                currentPage: 1,
                perPageOptions: [5, 10, 25, 50],
                total: 0,
            },
        }
    },
    computed: {
        ...mapState('notificationEmail', ['notificationEmailLogs']),
        to() {
            let highBound = this.from + this.pagination.perPage;
            if (this.total < highBound) {
                highBound = this.total;
            }
            return highBound;
        },
        from() {
            return this.pagination.perPage * (this.pagination.currentPage - 1);
        },
        total() {
            return this.notificationEmailLogs.length
        }
    },
    methods: {
        ...mapActions('notificationEmail', ['getNotificationEmailLogs']),
        closeModal() {
            this.$emit("evtClose");
        },
        getDate(date) {
            if (!date) return '-';
            return moment.utc(String(date)).format('DD/MM/YYYY')
        },
        getHour(date) {
            if (!date) return '-';
            return moment.utc(String(date)).format('HH:mm')
        },
    },
    async mounted() {
        this.dialogVisible = true;
    },
    destroyed() {
        this.dialogVisible = false;
    }
}
</script>
<style>
.modal-footer button {
    margin: 10;
    padding-left: 16px;
    padding-right: 16px;
    width: auto;
}

.el-button--primary.is-disabled,
.el-button--primary.is-disabled:active,
.el-button--primary.is-disabled:focus,
.el-button--primary.is-disabled:hover {
    color: #FFF;
    background-color: #7ed3d8;
    border-color: #7ed3d8;
}

.el-button:focus,
.el-button:hover {
    color: #FFF;
    border-color: #00ADB7;
    background-color: #00ADB7;
}

.el-button--primary {
    color: #FFF;
    background-color: #4eacb1;
    border-color: #4eacb1;
}
</style>