<template>
  <div class="row">
    <div class="col-12">
      <card type="tasks" class="stacked-form">
        <div class="col-7">
          <div class="row">
            <div class="col-8">
              <base-input>
                <label>Filial</label>
                <el-select v-model="costCenterConfiguration.BPLId" placeholder="Selecione uma filial"
                  :disabled="disabledField">
                  <el-option v-for="branch in branches" :key="branch" :label="branch" :value="branch">
                  </el-option>
                </el-select>
              </base-input>
            </div>
            <div class="col-4 d-flex align-items-center justify-content-center">
              <base-input>
                <label>Status:</label><br />
                <toggle-button :labels="{ checked: 'ON', unchecked: 'OFF' }" color="#7dd7dc" input type="checkbox"
                  v-model="costCenterConfiguration.Active" :sync="true" />
              </base-input>
            </div>
          </div> 
          <div class="row">
            <div class="col-8">
              <base-input>
                <label>Descrição</label>
                <el-input v-model="costCenterConfiguration.Description" placeholder="Informe a descrição do Centro de Custo"
                  type="text"/>
              </base-input>
            </div>
            </div>         
        </div>
      </card>
      <CostCenterConfigurationOption ref="options" :options="costCenterConfiguration.Options" />
    </div>
  </div>
</template>
<script>
import { mapActions,mapState } from 'vuex';
import { EventBus } from "@/eventBus";
import CostCenterConfigurationOption from './CostCenterConfigurationOption.vue';

export default {
  components: { CostCenterConfigurationOption },
  computed: {
    ...mapState("costCenter", ["costCenterConfigurationCurrent"]),
    ...mapState(["ecommerce"]),
  },
  data() {
    return {
      costCenterConfiguration: {
        EcommerceName: "",
        Description: "",
        BPLId: null,
        Active: false,
        Options: []
      },
      branches:[],
      disabledField: false,
      showModal: false
    };
  },
  methods: {
    ...mapActions("configurations",["getWarehouses"]),
    save() {
      this.$emit("save");
    },
    validate() {
      if (this.$isNullOrEmpty(this.costCenterConfiguration.BPLId) || this.costCenterConfiguration.BPLId ==0) {
        this.$showError("Filial obrigatória.");
        return false;
      }
      if (this.$isNullOrEmpty(this.costCenterConfiguration.Description)) {
        this.$showError("Descrição obrigatória.");
        return false;
      }
      if (this.costCenterConfiguration.Options.length==0) {
        this.$showError("Opção de Centro de custo obrigatória");
        return false;
      }
      
      return true;
    },
    getValue() {
      return this.costCenterConfiguration;
    },
    async load() {
      this.costCenterConfiguration = this.costCenterConfigurationCurrent;
      this.disabledField = true;
    },
    showModalOption() {
      this.showModal = true;
    },
    closeModalOption() {
      this.showModal = false;
    },
    getBranches(){
      this.getWarehouses()
      .then(res => {
        res.data.forEach((el) => {
          if(!this.branches.find(x => x == el.Filial))
            this.branches.push(el.Filial);
        });
      })
      .catch(error => { this.$showError(`Erro ao buscar Warehouses, motivo: ${error.response.data}`)});
    }
  },
  mounted() {
    this.getBranches();
    EventBus.$on("loadCostCenterConfiguration", async () => this.load());    
  }  
}
</script>
<style>
.el-switch.is-checked .el-switch__core {
  border-color: #00ADB7 !important;
  background-color: #00ADB7 !important;
}

.el-switch__label.is-active {
  color: #00ADB7 !important;
}

.el-select-dropdown__item.selected {
  color: #00ADB7;
  font-weight: 700;
}

.form-group .el-select .el-input.is-disabled .el-input__inner {
  cursor: not-allowed !important;
}
</style>
