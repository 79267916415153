export function ValidateForm(self, Seller) {

    if (!Seller.Name) {
        ShowWarningNotification(self, 'Por favor, informe a referência no <b> Registro </b>');
        return false;
    }

    if (!Seller.EcommerceReference) {
        ShowWarningNotification(self, 'Por favor, informe a referência no <b> E-Commerce </b>');
        return false;
    }

    if (!Seller.HubReference) {
        ShowWarningNotification(self, 'Por favor, informe a referência no <b> HUB </b>');
        return false;
    }

    if (!Seller.ErpReference) {
        ShowWarningNotification(self, 'Por favor, informe a referência no <b> ERP </b>');
        return false;
    }

    return true;
}

export function FiltersValidation(self, filters) {
    const { name, type } = filters;

    if (!name || !type) { 
        ShowWarningNotification(self, 'Por favor, informe ambos os filtros');
        return false;
    }

    return true;
}

export function ShowWarningNotification(self, message) {
    self.$notify({
        message: message,
        timeout: 3000,
        icon: "tim-icons icon-bell-55",
        horizontalAlign: "right",
        verticalAlign: "top",
        type: "danger"
    });

    return;
}