103 lines (96 sloc) 2.82 KB
<template>
  <div class="example-simple">
    <h4 id="example-title" class="card-title">Exportar Arquivo de Imagens</h4>
    <div class="upload">

      <card v-if="flVisible" id="divDownload" card-body-classes="table-full-width">
        <h4 slot="header" class="card-title">Imagens VTex - Completo</h4>

        <p class="description-export">
          Gera um arquivo CSV contendo todos as SKUs e links das imagens que estão armazenadas no Google Drive. Esse
          processo pode levar até 15 minutos para ser concluído.
        </p>

        <div class="example-btn">
          <a class="btn btn-success" @click="downloadSheet"><i class="fa fa-arrow-down" aria-hidden="true"></i> Download
            Arquivo</a>
        </div>
      </card>

      <card v-else id="divAviso" card-body-classes="table-full-width">
        <h4 slot="header" class="card-title">Imagens VTex</h4>

        <label id="lblAvisoInicial" name="lblAvisoInicial">
          <h4> Arquivo sendo gerado, por favor aguarde na tela.</h4>
        </label><br>
      </card>

      <card id="divDownload" card-body-classes="table-full-width">
        <h4 slot="header" class="card-title">Imagens VTex - Filtradas por SKU</h4>

        <p class="description-export">
          Realize o upload do arquivo com as SKUs e será gerado um arquivo CSV contendo os links das imagens que estão
          armazenadas no Google Drive. Esse processo pode levar até 2 minutos para ser concluído.
        </p>

        <div class="upload">
          <ul>
            <li v-for="(file) in files" :key="file.id">
              <span>{{ file.name }}</span> -
              <span>{{ file.size | formatSize }}</span>
            </li>
          </ul>
          <div class="example-btn">
            <file-upload class="btn btn-primary" post-action="/upload/post" extensions="xlsx" accept=".xlsx"
              :multiple="false" :size="1024 * 1024 * 10" v-model="files" @input-filter="inputFilter"
              @input-file="inputFile" ref="upload">
              <i class="fa fa-plus"></i>
              Selecionar Arquivo
            </file-upload>
            <button type="button" class="btn btn-success"
              v-if="getUserPermission('10_c') && (!$refs.upload || !$refs.upload.active)"
              @click.prevent="$refs.upload.active = true">
              <i class="fa fa-arrow-up" aria-hidden="true"></i>
              Começar Importação
            </button>
            <button type="button" class="btn btn-danger" v-else @click.prevent="$refs.upload.active = false">
              <i class="fa fa-stop" aria-hidden="true"></i>
              Parar importação
            </button>
          </div>
        </div>
      </card>

      <card card-body-classes="table-full-width">
        <h4 slot="header" class="card-title">Pesquisar Imagens</h4>

        <label id="lblAvisoInicial" name="lblAvisoInicial">
          <h4> Informe o U_Material da SKU.</h4>
        </label><br>

        <base-input>
          <el-input class="mt-3" placeholder="U_Material" v-model="U_Material"></el-input>
        </base-input>

        <base-button class="mt-3" native-type="submit" type="primary" @click="find()">Pesquisar</base-button>

        <div v-for="item in imagens" v-bind:key="item.id">
          <br>
          Id: {{ item.id }}<br>
          Nome: {{ item.name }}<br>
          Tipo: {{ item.mimeType }}<br>
          Link: <a v-bind:href="'https://drive.google.com/uc?export=view&id=' + item.id"
            target="_blank">Donwload</a><br>
          <hr>
        </div>
      </card>
    </div>
    <div class="pt-5">
    </div>
  </div>

</template>




<style>
@import './Products.css';
</style>

<script>
import FileUpload from 'vue-upload-component'
import swal from "sweetalert2";
export default {
  components: {
    FileUpload,
  },
  data() {
    return {
      files: [],
      addFile: true,
      flVisible: true,
      url: "",
      imagens: [],
      U_Material: ""
    }
  },
  methods: {
    async downloadSheet() {
      let response = await this.$bahngleis.get(
        `/api/Export/ExportProductsToCsv?ecommerce=${this.$store.state.ecommerce.Name}`,
        {
          responseType: "blob",
        }
      );

      this.downloadFile("ExportProducts.csv", response.data);
    },
    downloadFile(file, fileContent) {
      const url = window.URL.createObjectURL(new Blob([fileContent]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", file);
      document.body.appendChild(link);
      link.click();
      return;
    },
    async find() {
      this.imagens = [];

      const url2 = "/api/GoogleDrive/FindImagens/" + this.$store.state.ecommerce.Name + "?sku=" + this.U_Material;

      let response = await this.$bahngleis.get(url2);

      this.imagens = response.data;
    },
    inputFilter(newFile, oldFile, prevent) {
      if (newFile && !oldFile) {
        // Before adding a file
        // Filter system files or hide files

        if (!/\.(xlsx)$/i.test(newFile.name)) {
          return prevent()
        }
      }
    },
    async inputFile(newFile, oldFile) {
      var vm = this;
      if (newFile && !oldFile) {
        // add
        this.addFile = true;
      }
      if (newFile && oldFile) {
        // update
        let formData = new FormData();
        formData.append("file", this.$refs.upload.files[0].file);

        let config = {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        };

        if (this.addFile) {
          this.addFile = false;
          this.$bahngleis.defaults.timeout = 600000;

          await this.$bahngleis
            .post(`/api/Export/Import?ecommerceName=${this.$store.state.ecommerce.Name}`, formData, config)
            .then(async function (response) {

              if (response.status === 200) {
                swal({
                  title: "Sucesso",
                  text: "O arquivo foi gerado corretamente",
                  type: "success",
                  confirmButtonClass: "btn btn-success btn-fill",
                  buttonsStyling: false
                });
                vm.addFile = true;
                vm.$refs.upload.files[0].file.success = true;

                var headers = response.headers;
                var blob = new Blob([response.data], { type: headers['text/csv'] });
                var link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = "Imagens_Filtradas_Por_SKU.csv";
                link.click();

              }
              else {
                swal({
                  title: "Atenção",
                  text: "Ocorreu um erro ao gerar o arquivo. " + response.message,
                  type: "error",
                  confirmButtonClass: "btn btn-success btn-fill",
                  buttonsStyling: false
                });
              }
            })
            .catch((err) => this.$showError(err));
        }
      }
      if (!newFile && oldFile) {
        // remove
      }
    },
    getUserPermission(role) {
      return this.$keycloak.hasRealmRole(role);
    }
  }
}
</script>
