<template>
  <transition name="fade" mode="out-in">
    <div v-if="!showDetails">
      <card card-body-classes="table-full-width">
        <h4 slot="header" class="card-title" id="v-step-24">Status</h4>
        <div>
          <svg v-if="showSpinner" class="spinner-lg" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
            <circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30" />
          </svg>

          <el-table :data="StatusData">
            <el-table-column v-for="column in tableColumns" :key="column.label" :min-width="column.minWidth"
              :prop="column.prop" :label="column.label" align="center"></el-table-column>
            <el-table-column :min-width="135" align="right" label="Ações">
              <div slot-scope="props">
                <base-button class="like !important btn-link" type="primary" size="sm" icon
                  @click="ShowDetails(props.row)" v-if="getUserPermission('40_c')">
                  <i class="material-icons">mode_edit_outline</i>
                </base-button>
              </div>
            </el-table-column>
          </el-table>
        </div>

      </card>
    </div>

    <div v-else class="row">
      <div class="col-12">
        <h4 slot="header" class="card-title">
          <base-button class="like !important btn-link" type="primary" size="sm" icon @click="HideDetails()">
            <i class="material-icons">arrow_back</i> </base-button>Detalhes do status
        </h4>
      </div>
      <div class="col-3">
        <card type="tasks" class="stacked-form">
          <h4 slot="header" class="card-title">Nome BAHN</h4>
          <div class="table-full-width table-responsive">
            <ul>
              <li v-for="item in StatusData" :key="item.ID" class="custom-list-item">
                <base-radio v-model="Status.BahnReference" :name="item.BahnReference"
                  v-if="Status.BahnReference === item.BahnReference">&nbsp;{{ item.BahnReference }}</base-radio>
                <base-radio v-model="Status.BahnReference" :name="item.BahnReference" v-else disabled>&nbsp;{{
                  item.BahnReference
                }}</base-radio>

              </li>
            </ul>
          </div>
        </card>
      </div>
      <div class="col-3">
        <card type="tasks" class="stacked-form">
          <h4 slot="header" class="card-title">Apelido</h4>
          <div class="col-12">
            <base-input>
              <el-input placeholder="Nome" v-model="Status.AliasName"></el-input>
            </base-input>
          </div>
        </card>
      </div>
      <div class="col-3">
        <card type="tasks" class="stacked-form">
          <h4 slot="header" class="card-title">Nome E-Commerce</h4>
          <div class="col-12">
            <base-input>
              <el-input placeholder="Nome" v-model="Status.EcommerceReference"></el-input>
            </base-input>
          </div>
        </card>
      </div>

      <div class="col-3">
        <card type="tasks" class="stacked-form">
          <h4 slot="header" class="card-title">Nome ERP</h4>
          <div class="col-12">
            <base-input>
              <el-input placeholder="Nome" v-model="Status.ErpReference"></el-input>
            </base-input>
          </div>
        </card>
      </div>
      <div class="col-lg-12 col-sm-12">
        <UpdateLog :dataLog="dataLog" />
      </div>
      <div class="col-12 table-full-width table-responsive">
        <div class="d-flex justify-content-center justify-content-between flex-wrap">
          <div></div>
          <div>
            <button class="custom-btn-save" @click="Save()">Salvar</button>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
import swal from "sweetalert2";
import { BaseCheckbox, BaseRadio } from "src/components/index";
import UpdateLog from "../../components/UpdateLog.vue";

export default {
  components: {
    BaseCheckbox,
    BaseRadio,
    UpdateLog
  },
  data() {
    return {
      dataLog: [],
      showDetails: false,
      Filters: {
        type: null,
        name: null,
        typeOptions: [
          { name: "Filtrar por...", value: null },
          { name: "Apelido", value: "aliasName" },
          { name: "Nome E-Commerce", value: "ecommerceReference" },
          { name: "Nome Bahn", value: "bahnReference" },
          { name: "Nome ERP", value: "erpReference" }
        ]
      },

      tableColumns: [
        {
          prop: "AliasName",
          label: "Apelido",
          minWidth: 180
        },
        {
          prop: "EcommerceReference",
          label: "Nome E-Commerce",
          minWidth: 180
        },
        {
          prop: "BahnReference",
          label: "Nome Bahn",
          minWidth: 180
        },
        {
          prop: "ErpReference",
          label: "Nome ERP",
          minWidth: 180
        }
      ],
      showSpinner: false,
      StatusData: null,
      Status: null
    };
  },
  mounted() {
    this.GetStatus();
  },
  methods: {
    async GetStatus() {
      try {
        this.showSpinner = true;
        let response = await this.$bahngleis.get(`Status/GetByWithFilter?ecommerceName=${this.$store.state.ecommerce.Name}`);
        this.StatusData = response.data;
        this.ClearFilters();
      } catch (error) {
        this.$showError(error);
      } finally {
        this.showSpinner = false;
      }
    },
    ClearFilters: function () {
      this.Filters.type = null;
      this.Filters.name = null;
    },
    FilterStatus: async function () {
      if (this.$isNullOrEmpty(this.Filters.name) || this.Filters.type == null) {
        this.ShowWarningNotification("Por favor, informe ambos os filtros");
      } else {
        this.showSpinner = true;
        let response = await this.$bahngleis.get(
          `Status/GetByWithFilter?ecommerceName=${this.$store.state.ecommerce.Name}` +
          `&${this.Filters.type}=${this.Filters.name}`
        );

        this.StatusData = await response.data;
        this.showSpinner = false;
      }
    },
    ShowDetails: async function (status) {
      this.Status = status;
      this.showDetails = !this.showDetails;
      await this.GetLogAction(status);
    },
    GetLogAction: async function (status) {
      let responseLog = await this.$bahngleis.get(
        "/LogAction/getlogsofentity?identity=" +
        status.ID + this.$store.state.ecommerce.Name +
        "&entity=Status"
      );

      this.dataLog = responseLog.data;
    },
    HideDetails: function () {
      this.Status = null;
      this.showDetails = !this.showDetails;
      this.GetStatus();
    },
    Validate() {
      if (this.$isNullOrEmpty(this.Status.BahnReference)) {
        this.ShowWarningNotification(
          "Por favor, selecione ao menos um nome no <b>BAHN</b>"
        );
        return false;
      }
      if (this.$isNullOrEmpty(this.Status.AliasName)) {
        this.ShowWarningNotification(
          "Por favor, informe o apelido"
        );
        return false;
      }
      return true;
    },
    Save() {
      if (this.Validate()) {
        this.Update();
      }
    },
    Update: async function () {
      this.$bahngleis
        .put("Status", this.Status)
        .then(async () => {
          await this.GetLogAction(this.Status)
            .then(() => {
              this.$showSuccess("O status foi atualizado com sucesso")
              this.HideDetails();
            })
            .catch(error => {
              this.$showError(error)
            });
        });
    },
    //---- Auxiliaries ---------------------------------------------------------
    ShowWarningNotification: function (message) {
      this.$notify({
        message: message,
        timeout: 3000,
        icon: "tim-icons icon-bell-55",
        horizontalAlign: "right",
        verticalAlign: "top",
        type: "danger"
      });
      return;
    },
    getUserPermission(role) {
      return this.$keycloak.hasRealmRole(role);
    }
  }
};
</script>

<style>
@import "./Status.css";
</style>
