<template>
  <transition name="fade" mode="out-in">
    <card card-body-classes="table-full-width">
      <h3 slot="header" class="card-title">Cadastrar forma de pagamento</h3>
      <PaymentMethodForm :typeForm="typeForm" ref="form" />
      <div slot="footer" class="col-12 d-flex justify-content-end flex-wrap">
        <div class="col-4">
          <button class="custom-btn-add m-1" @click="$router.push({ name: 'PaymentMethods' })">Voltar</button>
          <button class="custom-btn-save m-1" @click="save">Salvar</button>
        </div>
      </div>
    </card>
  </transition>
</template>
<script>
import PaymentMethodForm from "../components/PaymentMethodForm.vue";
import { mapActions, mapState } from "vuex";
import constants from "@/util/constants";

export default {
  components: {
    PaymentMethodForm,
  },
  data() {
    return {
      typeForm: constants.typeForm.create,
    };
  },
  computed: {
    ...mapState(['realm','ecommerce']),
  },
  methods: {
    ...mapActions("paymentMethod", ["savePaymentMethod", "insertECompayment"]),
    async save() {
      let form = this.$refs.form;

      if (!form.isFormValid())
        return;

      let payload = form.prepareFormToSend();
      await this.savePaymentMethod(payload).then(() => {
        this.$showSuccess("Forma de pagamento cadastrada com sucesso");

        if (this.realm === "vulcabras")
          this.insertECompaymentVulcabras(payload);

        this.$router.push({ name: "PaymentMethods" });
      }).catch(error => {
        if (error.response.data)
          this.$showError(error.response.data);
        else
          this.$showError(error);
      });
    },
    insertECompaymentVulcabras: function (payload) {
      var data = {
        EcommerceName: this.ecommerce.Name,
        Code: payload.Name,
        Name: payload.Name,
        U_ACCTCode: payload.Account,
        U_CardIdVT: payload.CreditCardCode,
        U_Payment_Method: payload.PaymentMethodType,
        U_PayMethCod: payload.ErpReference,
        U_Brand: payload.Brand,
        U_SalesChannel: payload.Channel,
        U_EcomPayment: payload.EcommerceReference
      };

      this.insertECompayment(data);
    },
  },
};
</script>
<style>
.el-dropdown-menu__item:focus,
.el-dropdown-menu__item:not(.is-disabled):hover {
  background-color: #00ADB7;
  color: #ffffff !important;
}

.el-button-group>.el-button.is-active,
.el-button-group>.el-button:active,
.el-button-group>.el-button:focus,
.el-button-group>.el-button:hover {
  color: #ffffff !important;
  background-color: #00ADB7 !important;
}

.el-button {
  display: inline-block;
  color: #ffffff;
  background-color: #00ADB7;
}
</style>
