
<template>
    <div>
        <el-timeline>
            <el-timeline-item placement="top">
                <el-card>
                    <h4><icon src="logos:visual-studio"/> Instalação da IDE do backend</h4>
                    <p>IDE de preferencia para backend - Visual Studio <a href="https://visualstudio.microsoft.com/vs/community/">https://visualstudio.microsoft.com/vs/community/</a></p>
                </el-card>
            </el-timeline-item>
            <el-timeline-item placement="top">
                <el-card>
                    <h4><icon src="devicon:dotnetcore"/> SDK .Net</h4>
                    <p>Abra o prompt de comando e execute o seguinte comando "dotnet --list-sdks" verfique se existe a versão 2.2, caso não tenha baixe e instale o sdk  
                        <a href="https://dotnet.microsoft.com/en-us/download/dotnet/2.2">https://dotnet.microsoft.com/en-us/download/dotnet/2.2</a></p>
                </el-card>
            </el-timeline-item>
            <el-timeline-item  placement="top">
                <el-card>
                    <h4><icon src="logos:visual-studio-code"/> Instalação da IDE do frontend</h4>
                    <p>IDE de preferencia para frontend - Visual Studio Code: <a href="https://code.visualstudio.com/">https://code.visualstudio.com/</a></p>
                </el-card>
            </el-timeline-item>
            <el-timeline-item placement="top">
                <el-card>
                    <h4><icon src="mdi:git" color="#FD61OD"/> Git</h4>
                    <p>Verifique se já possui o Git instalado executando o seguinte comando no prompt <b>"git"</b>
                        Caso não possua, baixe e instale o Git: <a href="https://git-scm.com/downloads">https://git-scm.com/downloads</a>
                    </p>
                </el-card>
            </el-timeline-item>
            <el-timeline-item placement="top">
                <el-card>
                    <h4><icon src="logos:nodejs-icon"/> Node Js</h4>
                    <p>Instale o node js na versão 14.17.3: <a href="https://nodejs.org/en/blog/release/v14.17.3">https://nodejs.org/en/blog/release/v14.17.3</a>
                    </p>
                </el-card>
            </el-timeline-item>
        </el-timeline>
    </div>
</template>

<script>
export default {
    props: {
        title: String,
        content: String
    }
}
</script>

<style scoped>
p {
    color: black;
}

a {
    color: #0000EE;
}
</style>
