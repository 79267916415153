
<template>
     <div>
        <el-timeline>
            <el-timeline-item placement="top">
                <el-card>
                    <h4>Titulo</h4>
                    <p>Descrição</p>
                </el-card>
            </el-timeline-item>
        </el-timeline>
        <el-timeline>
            <el-timeline-item placement="top">
                <el-card>
                    <h4>Titulo</h4>
                    <p>Descrição</p>
                </el-card>
            </el-timeline-item>
        </el-timeline>
    </div>
</template>

<script>
export default {
    name: 'Wiki',
    props: {
        title: String,
        content: String
    }
}
</script>

<style scoped>
p {
    color: black;
}

a {
    color: #0000EE;
}
</style>
