<template>
    <div class="modal fade" id="logModal" data-backdrop="static" tabindex="-1" role="dialog" aria-labelledby="logModalLabel"
        aria-hidden="true">
        <div class="modal-dialog modal-sm" role="document">
            <div class="modal-content bg-color-default">
                <div class="modal-header">
                    <h4 slot="header" id="logModalLabel">
                        Atualizar Depósito
                    </h4>
                    <button type="button" class="close" @click="closeModal">
                        <span aria-hidden="true" class="text-white">
                            <i class="tim-icons icon-simple-remove"></i>
                        </span>
                    </button>
                </div>
                <div class="modal-body">
                    <div>
                        <base-input>
                            <el-select class="select-primary" v-model="warehouseCode" placeholder="Código de Depósito">
                                <el-option class="select-primary" v-for="(item, index) in warehouses" :key="index"
                                    :label="item.Code" :value="item.Code"></el-option>
                            </el-select>
                        </base-input>
                    </div>
                    <hr>
                    <div class="d-flex justify-content-center mt-3">
                        <button class="custom-btn-add" @click="closeModal">Cancelar</button>
                        <button class="custom-btn-filter ml-2" @click="save">{{button}}</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Modal from '@/components/Modal'
import { mapActions, mapState } from 'vuex';

export default {
    components: [
        Modal
    ],
    components: { Modal },
    data() {
        return {
            warehouseCode: null,
            warehouses: [],
            loading : false
        }
    },
    computed: {
        ...mapState("orders", ["ordersselected"]),
        button(){
            return this.loading ? "Carregando..." : "Atualizar";
        }
    },
    methods: {
        ...mapActions("orders", ["updateWarehouse"]),
        ...mapActions("configurations", ["getWarehouses"]),
        closeModal() {
            $(".modal-backdrop").hide();
            this.$router.push({ name: 'Orders' });
        },
        async save() {
            if (this.$isNullOrEmpty(this.warehouseCode)) {
                this.$showError("Selecionde um depósito!");
                return;
            }

            if (!this.ordersselected || this.ordersselected.length <= 0) {
                this.$showError("Nenhum pedido selecionado !");
                return;
            }

            if (this.ordersselected.length > 50) {
                this.$showError("Desculpe, você só pode atualizar até 50 pedidos por vez no máximo. Por favor, tente novamente com um número menor.");
                return;
            }

            this.loading = true;
            await this.updateWarehouse({ numbers: this.ordersselected, warehouseCode: this.warehouseCode })
            .then(() => {
                this.$showSuccess("Atualização de depósitos realizada com sucesso!");
                this.closeModal();
            })
            .catch(error => {
                this.$showError(error.response.data)
            })

            this.loading = false
        },
        closeModalOnOutsideClick(event) {
            if (document.body.classList.contains('modal-open') && !event.target.closest('.modal-dialog')) {
                this.closeModal();
            }
        },
    },
    mounted() {
        this.getWarehouses()
            .then(res => {
                this.warehouses = res.data.filter(warehouse => warehouse.IsIntegration);
            })
            .catch(error => { this.$showError(`Erro ao buscar Warehouses, motivo: ${error.response.data}`) });
            
        jQuery("#logModal").modal("show");
        document.addEventListener('click', this.closeModalOnOutsideClick);
    },
    destroyed() {
        document.removeEventListener('click', this.closeModalOnOutsideClick);
    },
}
</script>