export const tableColumns = [
    {
        prop: "Name",
        label: "Nome",
        minWidth: 250
    },
    {
        prop: "HubReference",
        label: "Nome no HUB",
        minWidth: 250
    },
    {
        prop: "EcommerceReference",
        label: "Nome no E-Commerce",
        minWidth: 100
    },
    {
        prop: "ErpReference",
        label: "Nome no ERP",
        minWidth: 120
    }
]