<template>
    <div class="row">
        <div class="col-12">
            <h4 slot="header" class="card-title">
                <base-button class="like !important btn-link" type="primary" size="sm" icon @click="$router.go(-1)">
                    <i class="material-icons">arrow_back</i>
                </base-button>Minha Conta
            </h4>
        </div>
        <div class="col-12">
            <AccountForm ref="form" />
        </div>
        <div class="col-12">
            <div class="d-flex justify-content-center justify-content-between flex-wrap">
                <div></div>
                <div>
                    <button class="custom-btn-save" @click="update">Atualizar</button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { mapActions } from "vuex";
import AccountForm from "../components/AccountForm.vue";

export default {
    components: {
        AccountForm
    },
    data() {
        return {
            info: {}
        }
    },
    methods: {
        ...mapActions('user', ['updateUser']),
        async loadAccount() {
            this.$keycloak.loadUserProfile().success(profile => {
                this.profile = profile;
            });
            this.$keycloak.loadUserInfo().success(info => {
                this.info = info;
            });
        },
        async update() {
            try {

                let form = this.$refs.form;
                const data = {
                    param: {
                        id: this.info.sub,
                        realm: this.$store.state.realm
                    },
                    formData: form.getValue()
                }

                await this.updateUser(data);
                this.$showSuccess("O cadastro foi atualizado com sucesso");
                this.$router.push({ name: 'Dashboard' });
            } catch (error) {
                if (error.response.status === 403) {
                    let errorMessage = "Ocorreu um erro ao atualizar o cadastro: Usuário não possui permissão para atualizar cadastro.";
                    this.$showError(errorMessage);
                } else
                    this.$showError("Ocorreu um erro ao atualizar o cadastro");
            }
        }
    },
    async mounted() {
        await this.loadAccount();
    }
}
</script>
