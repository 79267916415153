<template>
  <div class="row">
    <div class="col-12">
      <h4 slot="header" class="card-title">
        <base-button class="like !important btn-link" type="primary" size="sm" icon @click="$router.go(-1)">
          <i class="material-icons">arrow_back</i>
        </base-button> Detalhes Fulfillment
      </h4>
    </div>
    <div class="col-12">
      <FulfillmentConfigurationForm ref="form" />
    </div>
    <div class="col-12">
      <div class="d-flex justify-content-center justify-content-between flex-wrap">
        <div></div>
        <div>
          <button class="custom-btn-save" @click="update">Atualizar</button>
        </div>
      </div>
    </div>
    <div class="col-lg-12 col-sm-12">
      <UpdateLog :dataLog="logs" />
    </div>
  </div>
</template>
<script>
import { mapActions, mapMutations, mapState } from "vuex";
import FulfillmentConfigurationForm from "../components/FulfillmentConfigurationForm.vue";
import UpdateLog from "../../../components/UpdateLog.vue";
import { EventBus } from "@/eventBus";

export default {
  components: {
    FulfillmentConfigurationForm,
    UpdateLog
  },
  computed: {
    ...mapState('fulfillment', ['fulfillmentConfigurations', 'logs'])
  },
  methods: {
    ...mapActions('fulfillment', ['updateFulfillmentConfiguration', 'getLogFulfillmentConfiguration']),
    ...mapMutations('fulfillment', ['SET_FULFILLMENTCONFIGURATION_CURRENT']),
    getFulfillmentConfigurationCurrent() {
      let fulfillmentConfigurationId = this.$route.query.id;
      let fulfillmentConfiguration = this.fulfillmentConfigurations.Items.find(a => a.Id === fulfillmentConfigurationId);
      this.SET_FULFILLMENTCONFIGURATION_CURRENT(fulfillmentConfiguration);
      this.getLogFulfillmentConfiguration(fulfillmentConfigurationId);
      EventBus.$emit("loadFulfillmentConfiguration");
    },
    update() {
      let form = this.$refs.form;
      if (!form.validate())
        return;

      this
        .updateFulfillmentConfiguration(form.getValue())
        .then(() => {
          this.$showSuccess("O cadastro foi atualizado com sucesso")
          this.$router.push({ name: 'FulfillmentConfiguration' })
        })
        .catch(error => {
          this.$showError(error)
        });
    }
  },
  mounted() {
    this.getFulfillmentConfigurationCurrent();
  }
}
</script>
