<template>
  <div class="row">
    <div class="col-12">
      <h4 slot="header" class="card-title">
        <base-button class="like !important btn-link" type="primary" size="sm" icon @click="$router.go(-1)">
          <i class="material-icons">arrow_back</i>
        </base-button> Detalhes Centro de Custo
      </h4>
    </div>
    <div class="col-12">
      <CostCenterConfigurationForm ref="form" />
    </div>
    <div class="col-lg-12 col-sm-12">
      <UpdateLog :dataLog="logs" />
    </div>
    <div class="col-12">
      <div class="d-flex justify-content-center justify-content-between flex-wrap">
        <div></div>
        <div>
          <button class="custom-btn-save" @click="update">Atualizar</button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapMutations, mapState } from "vuex";
import CostCenterConfigurationForm from "../components/CostCenterConfigurationForm.vue";
import UpdateLog from "../../../components/UpdateLog.vue";
import { EventBus } from "@/eventBus";

export default {
  components: {
    CostCenterConfigurationForm,
    UpdateLog
  },
  computed: {
    ...mapState('costCenter', ['costCenterConfigurations', 'logs'])
  },
  methods: {
    ...mapActions('costCenter', ['updateCostCenterConfiguration', 'getLogCostCenterConfiguration']),
    ...mapMutations('costCenter', ['SET_COSTCENTERCONFIGURATION_CURRENT']),
    async getCostCenterConfigurationCurrent() {
      let costCenterConfigurationId = this.$route.query.id;
      let costCenterConfiguration = this.costCenterConfigurations.Items.find(a => a.Id === costCenterConfigurationId);
      this.SET_COSTCENTERCONFIGURATION_CURRENT(costCenterConfiguration);
      await this.getLogCostCenterConfiguration(costCenterConfigurationId);
      EventBus.$emit("loadCostCenterConfiguration");
    },
    update() {
      let form = this.$refs.form;
      if (!form.validate())
        return;

      this
        .updateCostCenterConfiguration(form.getValue())
        .then(() => {
          this.$showSuccess("O cadastro foi atualizado com sucesso")
          this.$router.push({ name: 'CostCenterConfiguration' })
        })
        .catch(error => {
          this.$showError(error)
        });
    }
  },
  async mounted() {
    await this.getCostCenterConfigurationCurrent();
  }
}
</script>
