<template>
  <div class="col-md-6">
    <card
      class="stacked-form"
      title="Teste de conectividade com SAP Service Layer"
    >
      <h4 slot="header" class="card-title">
        Teste de conectividade com SAP Service Layer
      </h4>
      <form @submit.prevent>
        <div>
          <base-input
            name="URL"
            label="URL da Service Layer"
            required
            placeholder="URL da Service Layer"
            v-model="ErpConfiguration.URL"
            v-validate="modelValidations.URL"
            :error="getError('URL')"
            addon-left-icon="tim-icons icon-link-72"
          >
          </base-input>
          <base-input
            name="Database"
            label="Base de dados"
            required
            placeholder="Base de dados"
            v-model="ErpConfiguration.Database"
            v-validate="modelValidations.Database"
            :error="getError('Database')"
            addon-left-icon="tim-icons icon-lock-circle"
          >
          </base-input>
          <base-input
            name="User"
            label="Usuário"
            required
            placeholder="Usuário"
            v-model="ErpConfiguration.User"
            v-validate="modelValidations.User"
            :error="getError('User')"
            addon-left-icon="tim-icons icon-single-02"
          >
          </base-input>
          <base-input
            name="Password"
            label="Senha"
            required
            placeholder="Senha"
            v-model="ErpConfiguration.Password"
            v-validate="modelValidations.Password"
            :error="getError('Password')"
            addon-left-icon="tim-icons icon-key-25"
            type="password"
          >
          </base-input>
          <base-button class="mt-3" native-type="submit" type="primary" @click="submit()"
            >Testar</base-button
          >
        </div>
      </form>
    </card>
  </div>
</template>
<script>
export default {
  data() {
    return {
      ErpConfiguration: {
        URL: "https://200.201.207.205:13030/b1s/v1/Login",
        Database: "SPS_TST_REGENER1508",
        User: "MANAGER",
        Password: "F1096740",
      },
      modelValidations: {
        URL: {
          required: true,
          url: {
            require_protocol: true,
          },
        },
        Database: {
          required: true,
        },
        User: {
          required: true,
        },
        Password: {
          required: true,
        },
      },
    };
  },
  methods: {
    getError(fieldName) {
      return this.errors.first(fieldName);
    },
    ShowWarningNotification: function (message) {
    this.$notify({
      message: message,
      timeout: 3000,
      icon: "tim-icons icon-bell-55",
      horizontalAlign: "right",
      verticalAlign: "top",
      type: "danger",
    });
    return;
  },ShowSuccessNotification: async function (message) {
    this.$notify({
      message: message,
      timeout: 3000,
      icon: "tim-icons icon-bell-55",
      horizontalAlign: "right",
      verticalAlign: "top",
      type: "success",
    });
    return;
  },
    submit: async function () {
      
      var flSuccess = false;
      var msg_error = "";

      let isValidateForm = this.$validator.validateAll().then((res) => {
         
        return res;
      });

      if (isValidateForm) {
        let isValidate = false;
        

        let vm = this;
        let response = await this.$bahngleis
        .post("api/LoginERP/TesteLogin", this.ErpConfiguration)
        .then(function () {

           self.flSuccess = true;
        }).catch(function(error){
            self.flSuccess = false;
            self.msg_error = error;
        });

      if(self.flSuccess){
        this.ShowSuccessNotification("Dados validados com sucesso");
        return;
      }
      else{
        this.ShowWarningNotification("Problema ao realizar a validação dos dados . Error: " + self.msg_error);
        return;
      }

      }
    },
  }
};
</script>
<style></style>
