<template>
    <div class="row">
        <div class="col-6">
            <card type="tasks" class="stacked-form">
                <div class="col-12">
                    <div class="row">
                        <div class="col-12">
                            <base-input>
                                <label>Nome de usúario:</label>
                                <el-input placeholder="Nome de usuário" v-model="profile.username" disabled>
                                </el-input>
                            </base-input>
                        </div>
                        <div class="col-12">
                            <base-input>
                                <label>E-mail:</label>
                                <el-input placeholder="Email" v-model="profile.email" disabled>
                                </el-input>
                            </base-input>
                        </div>
                        <div class="col-12">
                            <base-input>
                                <label>Primeiro nome:</label>
                                <el-input placeholder="Primeiro nome" v-model="profile.firstName">
                                </el-input>
                            </base-input>
                        </div>
                        <div class="col-12">
                            <base-input>
                                <label>Sobrenome:</label>
                                <el-input placeholder="Sobrenome" v-model="profile.lastName">
                                </el-input>
                            </base-input>
                        </div>
                        <div class="col-12">
                            <base-input>
                                <label>Nova Senha:</label>
                                <el-input placeholder="nova senha" v-model="obj.credentials[0].value">
                                </el-input>
                            </base-input>
                        </div>
                    </div>
                </div>
            </card>
        </div>
    </div>
</template>
<script>

export default {
    data() {
        return {
            profile: {
                username: null,
                firstName: null,
                emailVerified: null,
                email: null,
            },
            obj: {
                credentials: [
                    {
                        type: "password",
                        value: null,
                        temporary: false
                    }
                ]
            }

        }
    },
    methods: {
        save() {
            this.$emit("save");
        },
        validate() {

        },
        getValue() {
            return Object.assign(this.profile, this.obj.credentials[0].value ? this.obj : null);
        },
        loadAccount() {
            this.$keycloak.loadUserProfile().success(profile => {
                this.profile = profile;
            });
        },
    },
    mounted() {
        this.loadAccount();
    }
}
</script>
<style>
.el-switch.is-checked .el-switch__core {
    border-color: #00ADB7 !important;
    background-color: #00ADB7 !important;
}

.el-switch__label.is-active {
    color: #00ADB7 !important;
}

.el-select-dropdown__item.selected {
    color: #00ADB7;
    font-weight: 700;
}

.form-group .el-select .el-input.is-disabled .el-input__inner {
    cursor: not-allowed !important;
}
</style>
