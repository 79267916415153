<template>
    <Modal :modalContentClasses="'card'" :showClose="false" :show="show">
        <h4 slot="header" class="card-title">Adicionar Opção</h4>
        <base-input>
            <label>Tipo Centro de Custo</label>
            <el-select v-model="costCenterConfigurationOption.TypeCostCenter" placeholder="Selecione o Tipo Centro de Custo"
                @change="selectCostCenter">
                <el-option v-for="item in costCenter" :key="item.value" :label="item.description" :value="item.value">
                </el-option>
            </el-select>
        </base-input>
        <base-input>
            <label>Centro de Custo</label>
            <el-input v-model="costCenterConfigurationOption.ValueCostCenter" placeholder="Informe o Centro de custo" />
        </base-input>          
        <br>
        <div class="d-flex justify-content-between">
            <el-button type="primary" @click="addCostCenterConfigurationOption" class="ml-auto"
                :disabled="disabledAdd">Adicionar</el-button>
            <el-button @click="closeModal" style="margin-left: 10px;">Cancelar</el-button>
        </div>
    </Modal>
</template>
<script>
import Modal from '@/components/Modal';
import constants from '@/util/constants';

export default {
    props: {
        show: {
            type: Boolean,
            default: false
        },
    },
    components: {
        Modal
    },
    data() {
        return {
            costCenterConfigurationOption: {
                TypeCostCenter: '',
                ValueCostCenter: null
            },
            costCenter: [],
            disabledAdd: true,
        }
    },
    methods: {
        addCostCenterConfigurationOption() {
            if (!this.validate())
                return;

            this.$emit("evtAddedOption", this.costCenterConfigurationOption);
            this.costCenterConfigurationOption = {
               TypeCostCenter: '',
               ValueCostCenter: null
            }
        },
        closeModal() {
            this.$emit("evtClose");
        },
        loadOptions() {
            this.costCenter = constants.costCenter;
        },
        selectCostCenter(type) {
            this.disabledAdd = (type == '0' || type == '' ) ? true : false;
        },
        validate() {
            if (['0',''].includes(this.costCenterConfigurationOption.TypeCostCenter)) 
            {
                this.$showError("Tipo Centro de Custo obrigatório");
                return false;
            }
            if (this.$isNullOrEmpty(this.costCenterConfigurationOption.ValueCostCenter)) 
            {
                this.$showError("Centro de Custo obrigatório");
                return false;
            }
            
            return true;
        },
    },
    mounted() {
        this.loadOptions();
        this.dialogVisible = true;
    },
    destroyed() {
        this.dialogVisible = false;
    }
}
</script>
<style>
.modal-footer button {
    margin: 10;
    padding-left: 16px;
    padding-right: 16px;
    width: auto;
}

.el-button--primary.is-disabled,
.el-button--primary.is-disabled:active,
.el-button--primary.is-disabled:focus,
.el-button--primary.is-disabled:hover {
    color: #FFF;
    background-color: #7ed3d8;
    border-color: #7ed3d8;
}

.el-button:focus,
.el-button:hover {
    color: #FFF;
    border-color: #00ADB7;
    background-color: #00ADB7;
}

.el-button--primary {
    color: #FFF;
    background-color: #4eacb1;
    border-color: #4eacb1;
}

.el-switch.is-checked .el-switch__core {
    border-color: #00ADB7 !important;
    background-color: #00ADB7 !important;
}

.el-switch__label.is-active {
    color: #00ADB7 !important;
}

.el-select-dropdown__item.selected {
    color: #00ADB7;
    font-weight: 700;
}

.form-group .el-select .el-input.is-disabled .el-input__inner {
    cursor: not-allowed !important;
}
</style>