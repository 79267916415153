<template>
  <transition name="fade" mode="out-in">
    <card card-body-classes="table-full-width">
      <h4 slot="header" class="card-title">E-commerce</h4>
      <div>
        <div class="row" style="float: right">
          <span style="margin: 0 10pt">
            <base-input>
              <el-input type="search" prefix-icon="el-icon-search" placeholder="Nome" v-model="filters.name"></el-input>
            </base-input>
          </span>
          <span style="margin: 0 10pt">
            <button class="custom-btn-filter" @click="getEcommerceListFilter()">
              Filtrar
            </button>
          </span>
          <span style="margin: 0 10pt">
            <button class="custom-btn-filter" @click="clearFilter()">
              Limpar filtro
            </button>
          </span>
          <div v-if="getUserPermission('00_c')">
            <span style="margin: 0 10pt">
              <button class="custom-btn-add" @click="goToNew()">Novo</button>
            </span>
          </div>
        </div>
        <el-table :data="ecommerceList">
          <el-table-column v-for="column in tableColumns" :key="column.label" :min-width="column.minWidth"
            :prop="column.prop" :label="column.label" align="center"></el-table-column>
          <el-table-column label="Plataforma" align="center">
            <div slot-scope="props">
              {{ getPlatformNames(props.row.Platform) }}
            </div>
          </el-table-column>
          <el-table-column :min-width="120" align="center" label="Status">
            <div slot-scope="props">
              <p v-if="props.row.Active" class="status ecomerce-status-active">
                Ativo
              </p>
              <p v-else class="status ecomerce-status-inactive">
                Inativo
              </p>
            </div>
          </el-table-column>
          <el-table-column :min-width="135" align="right" label="Ações">
            <div slot-scope="props">
              <base-button class="like !important btn-link" type="primary" size="sm" icon @click="goToDetail(props.row)"
                v-if="getUserPermission('09_c')">
                <i class="material-icons">mode_edit_outline</i>
              </base-button>
            </div>
          </el-table-column>
        </el-table>
      </div>
      <div slot="footer" class="
            col-12
            d-flex
            justify-content-center justify-content-between
            flex-wrap
          ">
        <div class="col-5"></div>
        <div class="col-2">
          <label>
            Exibindo
            <span class="primary-text">{{ from + 1 }}</span> -
            <span class="primary-text">{{ to }}</span> de
            <span class="primary-text">{{ total }}</span> registros
          </label>
        </div>
        <div class="col-3">
          <base-pagination class="pagination-no-border" v-model="pagination.currentPage" :per-page="pagination.perPage"
            :total="total" @input="getEcommerceList"></base-pagination>
        </div>
        <div class="col-2">
          <el-select class="select-primary mb-3 pagination-select" style="width: 100px !important"
            v-model="pagination.perPage" placeholder="Per page">
            <el-option class="select-primary" v-for="item in pagination.perPageOptions" :key="item" :label="item"
              :value="item"></el-option>
          </el-select>
        </div>
      </div>
    </card>
  </transition>
</template>

<script>
import planMixin from "src/mixins/planMixin.js";
import { BasePagination, BaseRadio } from "src/components";
import { mapMutations, mapState } from "vuex";
import constants from "../../util/constants";

export default {
  mixins: [planMixin],
  components: {
    BaseRadio,
    BasePagination,
  },
  data() {
    return {
      ecommerceList: [],
      platFormList: [],
      erpList: [],
      filters: {
        name: null,
      },
      tableColumns: [
        {
          prop: "Name",
          label: "Nome",
          minWidth: 250,
        },
        {
          prop: "Url",
          label: "URL API",
          minWidth: 100,
        },
      ],
      pagination: {
        perPage: 5,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0,
      },
    };
  },
  computed: {
    ...mapState(["realm"]),
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.pagination.total;
    },
  },
  async mounted() {
    await this.getEcommerceList();
    await this.getPlatformList();
    await this.getErpList();
  },
  methods: {
    ...mapMutations("ecommerceView", [
      "SET_ECOMMERCE_CURRENT",
      "SET_ERP_LIST"
    ]),
    async clearFilter() {
      this.filters.name = null;
      this.filters.type = null;
      await this.getEcommerceList();
    },
    async getEcommerceList() {
      if (!this.$isNullOrEmpty(this.filters.name)) {
        await this.getEcommerceListFilter();
        return;
      }
      await this.$bahngleis
        .get(
          (`Ecommerce/GetPaginated?`) +
          `currentPage=${this.pagination.currentPage}&perPage=${this.pagination.perPage}`
        ).then((response) => {
          this.ecommerceList = response.data.Items;
          this.pagination.total = response.data.Total;
        });
    },
    async getEcommerceListFilter() {
      if (this.$isNullOrEmpty(this.filters.name)) {
        this.ShowWarningNotification("Por favor, informe o filtro!");
        return;
      }
        await this.$bahngleis
        .get(
          (`Ecommerce/GetPaginated?name=${this.filters.name}`) +
          `&currentPage=${this.pagination.currentPage}&perPage=${this.pagination.perPage}`
        ).then((response) => {
          this.ecommerceList = response.data.Items;
          this.pagination.total = response.data.Total;
        });
    },
    goToDetail(ecommerce) {
      this.SET_ECOMMERCE_CURRENT(ecommerce);
      this.$router.push({ name: "EcommerceDetail", params: { platFormList: this.platFormList, erpList: this.erpList } });
    },
    goToNew() {
      this.$router.push({ name: "EcommerceCreate", params: { platFormList: this.platFormList, erpList: this.erpList } });
    },
    async getPlatformList() {
      let platforms = constants.EcommercePlatForm.map((el) => {
        return { value: el.Value, label: el.Label };
      });
      this.platFormList = platforms;
    },
    async getErpList() {
      let erps = constants.ErpPlatform.map((el) => {
        return { value: el.Value, label: el.Label };
      });
      this.erpList = erps;
    },
    ShowWarningNotification: function (message) {
      this.$notify({
        message: message,
        timeout: 3000,
        icon: "tim-icons icon-bell-55",
        horizontalAlign: "right",
        verticalAlign: "top",
        type: "danger",
      });
      return;
    },
    getUserPermission(role) {
      return this.$keycloak.hasRealmRole(role);
    },
    getPlatformNames(key) {
      return constants.EcommercePlatForm.find((a) => a.Value === key).Label;
    },
  },
};
</script>

<style scoped>
.showPassword {
  padding-top: 5pt !important;
}

.validate-url {
  margin-left: 2em;
  color: white;
}

.ecomerce-status-inactive {
  color: #C3255B;
  border: 1px solid #C3255B;
  border-radius: 5px;
  font-weight: 500;
  font-size: 14px;
  background-color: rgba(195, 37, 91, 0.1);
}

.ecomerce-status-active {
  color: #6BDF06;
  border: 1px solid #6BDF06;
  border-radius: 5px;
  font-weight: 500;
  font-size: 14px;
  background-color: rgba(107, 223, 6, 0.1);
}
</style>
