<template>
  <div class="row" v-if="this.$store.state.ecommerce">
    <!-- Chart -->
    <div class="col-12">
      <card type="chart">
        <div slot="header">
          <div class="row">
            <div class="col-sm-4 text-left">
              <h3 class="card-title">Pedidos integrados com sucesso</h3>
            </div>
            <div class="col-sm-2 text-center justify-content-center d-flex align-items-center"
              style="padding-bottom: 10px;">
              <input type="checkbox" id="checkbox" v-model="showAllEcommerce" @change="changeEcommerce">
              <label
                style="color: #1aac96; font-size:12.6px; margin: 0px 15px 0px 10px; font-family: 'Poppins', sans-serif; font-weight: 300;"
                for="checkbox"> Todos os e-commerce</label>
            </div>
            <div class="col-sm-4 d-flex d-sm-block justify-content-center">
              <div class="btn-group btn-group-toggle float-right" data-toggle="buttons">
                <label v-for="(option, index) in chartCategories" :key="option" class="btn btn-sm btn-primary btn-simple"
                  :class="{ active: Chart.activeIndex === index }" :id="index" @click="SetChartData(index)">
                  <input type="radio" name="options" autocomplete="off" :checked="Chart.activeIndex === index" />
                  {{ option }}
                </label>
              </div>
            </div>
          </div>
        </div>
        <div>
          <GChart v-if="renderForceChart" type="ColumnChart" :data="chartData" :options="chartOptions" style=""
            :resizeDebounce="10" />
        </div>
      </card>
      <card class="pl-4">
        <div class="d-flex justify-content-between">
          <div class="pb-2 pt-2 ">Período: {{ filterDashboardSales.dateInitial | toDate }} até {{
            filterDashboardSales.dateFinal | toDate }}</div>
          <div class="p-2">Última atualização: {{ dashboardSales.LastUpdate | toDateTime }}</div>
        </div>
        <el-row :gutter="20">
          <el-col :span="4" style="border-left-style: double; border-color: #1aac96; border-width: 2px;">
            <div>
              <p><strong>Receita</strong></p>
              <p>{{ dashboardSales.EarnedRevenue | toCurrency }}</p>
            </div>
          </el-col>
          <el-col :span="5" style="border-left-style: double; border-color: #1aac96; border-width: 2px;">
            <div>
              <p><strong>Pedidos</strong></p>
              <p>{{ dashboardSales.CapturedOrders }}</p>
            </div>
          </el-col>
          <el-col :span="5" style="border-left-style: double; border-color: #1aac96; border-width: 2px;">
            <div>
              <p><strong>Ticket médio</strong></p>
              <p>{{ dashboardSales.AverageTicket | toCurrency }}</p>
            </div>
          </el-col>
          <el-col :span="5" style="border-left-style: double; border-color: #1aac96; border-width: 2px;">
            <div>
              <p><strong>Itens por pedido</strong></p>
              <p>{{ dashboardSales.ItemsPerOrder }}</p>
            </div>
          </el-col>
          <el-col :span="4" style="border-left-style: double; border-color: #1aac96; border-width: 2px;">
            <div>
              <p><strong>Preço médio por item</strong></p>
              <p>{{ dashboardSales.AveragePricePerItem | toCurrency }}</p>
            </div>
          </el-col>
        </el-row>
      </card>
    </div>
    <!-- Cards -->
    <div class="col-lg-3 col-md-6" v-for="card in statsCards" :key="card.index">
      <svg v-if="showSpinner" class="spinner-sm" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
        <circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30" />
      </svg>
      <el-tooltip :content="card.tooltip" effect="light" :open-delay="300" placement="top">
        <stats-card :title="card.title" :sub-title="card.footer" :type="card.type" :icon="card.icon"
          v-if="checkCard(card.cardView)">
          <div slot="footer" align="right">
            <span class="btn btn-sm btn-primary btn-simple active" @click="goToOrders(card.typeFilter)">Visualizar</span>
          </div>
        </stats-card>
      </el-tooltip>
    </div>
  </div>
</template>
<script>
import StatsCard from "src/components/Cards/StatsCard";
import { mapMutations, mapActions, mapState } from 'vuex';
import constants from '@/util/constants';
import moment from 'moment';
import { GChart } from 'vue-google-charts/legacy';
import { EventBus } from "@/eventBus";

export default {
  components: {
    StatsCard,
    GChart
  },
  data() {
    const today = new Date(moment());
    const firstDayInMonth = new Date(moment().subtract(30, 'days'));
    return {
      renderForceChart: true,
      showSpinner: false,
      configurations: [],
      Chart: {
        activeIndex: 0
      },
      chartCategories: ["Semanal", "Quinzenal", "Mensal"],
      weeklyOrders: null,
      biweeklyOrders: null,
      monthlyOrders: null,
      integratedToday: null,
      integratedLastHour: null,
      ordersPending: null,
      ordersWarning: null,
      ordersTransfers: null,
      nameEcommerce: null,
      statsCards: [
        {
          index: 1,
          title: "0",
          subTitle: "Total",
          type: "success",
          tooltip: "Visualizar pedidos de hoje",
          typeFilter: "today",
          cardView: "true",
          icon: "tim-icons icon-check-2",
          footer: "</i> Pedidos integrados <span style=\"color: #1aac96;\">hoje</span>"
        },
        {
          index: 2,
          title: "0",
          subTitle: "Total",
          type: "info",
          typeFilter: "lastHours",
          cardView: "true",
          tooltip: "Visualizar pedidos da última hora",
          icon: "tim-icons icon-time-alarm",
          footer: "</i> Pedidos integrados na <span style=\"color: #1aac96;\">última hora</span>"
        },
        {
          index: 3,
          title: "0",
          subTitle: "Total",
          type: "warning",
          typeFilter: "processing",
          cardView: "true",
          tooltip: "Visualizar pedidos em processamento",
          icon: "tim-icons icon-refresh-02",
          footer: "</i> Pedidos em <span style=\"color: #1aac96;\">processamento</span>"
        },
        {
          index: 4,
          title: "0",
          subTitle: "Total",
          type: "danger",
          typeFilter: "error",
          cardView: "true",
          tooltip: "Visualizar pedidos com erro",
          icon: "tim-icons icon-alert-circle-exc",
          footer: "</i> Pedidos que precisam de <span style=\"color: #1aac96;\">atenção</span>"
        },
        {
          index: 5,
          title: "0",
          subTitle: "Total",
          type: "custom",
          typeFilter: "waitingTransfer",
          cardView: "ShowCardOrderTransfers",
          tooltip: "Visualizar pedidos em transferência",
          icon: "tim-icons icon-vector",
          footer: "</i> Pedidos em <span style=\"color: #1aac96;\">transferência</span>"
        }
      ],
      colors: ['#5B91E7', '#378741', '#C1C23F', '#95B1AE', '#AF8598', '#334B49', '#EE9932', '#545479', '#98005F', '#9A59BE'],
      filterDashboardSales: {
        dateInitial: firstDayInMonth,
        dateFinal: today,
        ecommerceName: null
      },
      dashboardSales: {
        EarnedRevenue: 0.0,
        CapturedOrders: 0,
        AverageTicket: 0.0,
        ItemsPerOrder: 0,
        AveragePricePerItem: 0.0,
        LastUpdate: null
      },
      chartData: [],
      chartOptions: {
        tooltip: {
          isHtml: false
        },
        seriesType: 'bars',
        series: {
          0: { targetAxisIndex: 0 },
          1: { type: 'line', targetAxisIndex: 1 }
        },
        vAxes: {
          0: { title: 'Pedidos', format: 'short' },
          1: { title: 'Valor', format: 'short' }
        },
        hAxis: { title: 'Dias', format: 'R$ #,##0.00' },
        colors: ['#b3ecec', '#1aac96'],
        backgroundColor: localStorage.getItem('darkMode') === 'true' ? '#525f7f' : '#FFFFFF',
      },
    };
  },
  computed: {
    ...mapState(["ecommerce"]),
    showAllEcommerce:
    {
      get() {
        return this.$store.state.allEcommerce;
      },
      set(value) {
        this.updateCheckAllEcommerce(value);
      }
    }
  },
  methods: {
    ...mapActions("configurations", ["getConfigurationByKeys"]),
    ...mapActions(['getDashboardSales']),
    ...mapMutations(['SET_ALL_ECOMMERCE']),
    async SetChartData(index) {
      await this.requestDashboardSales();
      var data = [];
      if (index == 0) {
        data = this.weeklyOrders;
      } else if (index == 1) {
        data = this.biweeklyOrders;
      } else if (index == 2) {
        data = this.monthlyOrders;
      }

      const formattedValueOfSales = data.ValueOfSales.map(value => ({
        v: value,
        f: `R$${value.toFixed(2)}`
      }));

      this.chartData = [
        ['Dias', 'Quantidade de Pedidos', 'Vendas'],
        ...data.Labels.map((day, i) => [day, data.Values[i], formattedValueOfSales[i]])
      ];

      this.formatterChart();
    },
    async RequestDashboard() {

      var vm = this;
      await this.$bahngleis.get("dashboard/DashboardInfo/?ecommerceName=" + this.nameEcommerce).then(response => {
        this.weeklyOrders = response.data.ChartData.WeeklyData;
        this.biweeklyOrders = response.data.ChartData.BiweeklyData;
        this.monthlyOrders = response.data.ChartData.MonthlyData;
        response.data.CardsData.IntegratedToday == 0 ? vm.statsCards[0].title = '0' : vm.statsCards[0].title = (response.data.CardsData.IntegratedToday).toString()
        response.data.CardsData.IntegratedLastHour == 0 ? vm.statsCards[1].title = '0' : vm.statsCards[1].title = (response.data.CardsData.IntegratedLastHour).toString()
        response.data.CardsData.OrdersPending == 0 ? vm.statsCards[2].title = '0' : vm.statsCards[2].title = (response.data.CardsData.OrdersPending).toString()
        response.data.CardsData.OrdersWarning == 0 ? vm.statsCards[3].title = '0' : vm.statsCards[3].title = (response.data.CardsData.OrdersWarning).toString()
        response.data.CardsData.OrdersTransfers == 0 ? vm.statsCards[4].title = '0' : vm.statsCards[4].title = (response.data.CardsData.OrdersTransfers).toString()

        vm.SetChartData(0);
        vm.showSpinner = false;
      });
    },
    async requestDashboardSales() {
      await this.getDashboardSales(this.filterDashboardSales)
        .then((response) => {
          this.dashboardSales = response.data;
        })
        .catch((error) => this.$showError(error));
    },
    async changeEcommerce() {
      if (this.$store.state.allEcommerce) {
        this.nameEcommerce = "Todos";
      }
      else {
        this.nameEcommerce = this.$store.state.ecommerce.Name;
      }
      await this.RequestDashboard();
    },
    goToOrders(cardType) {
      this.$router.push({ name: 'Orders', params: { ecommerceName: this.nameEcommerce, useIntegratedData: true }, query: { orderType: cardType } });
    },
    updateCheckAllEcommerce(value) {
      this.SET_ALL_ECOMMERCE(value);
    },
    async getConfigurations() {
      let query = {
        ecommerceName: this.$store.state.ecommerce.Name,
        keys: [constants.Configurations.ShowCardOrderTransfers]
      };
      await this.getConfigurationByKeys(query).then(response => {
        this.configurations = response.data;
      });
    },
    checkCard(key) {
      if (key === "true") return true;

      let configuration = this.configurations.find(a => a.Key === key);
      return configuration ? configuration.Value === 'true' : true;
    },
    formatterChart() {
      const data = google.visualization.arrayToDataTable(this.chartData);

      const formatter = new google.visualization.NumberFormat({
        prefix: 'R$ ',
        decimalSymbol: ',',
        groupingSymbol: '.',
        decimalPlaces: 2
      });

      formatter.format(data, 2);
    }
  },
  async mounted() {
    await this.getConfigurations();
    this.showSpinner = true;
    this.nameEcommerce = this.$store.state.ecommerce.Name;
    await this.changeEcommerce();
    EventBus.$on('toggleMode', () => {
      this.chartOptions.backgroundColor = localStorage.getItem('darkMode') === 'true' ? '#525f7f' : '#FFFFFF';
    });
  }
};
</script>
