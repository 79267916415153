<template>
    <div class="row">
        <div class="col-12">
            <h4 slot="header" class="card-title">
                <base-button class="like !important btn-link" type="primary" size="sm" icon @click="$router.go(-1)">
                    <i class="material-icons">arrow_back</i>
                </base-button> Detalhe Notificação de E-mail
            </h4>
        </div>
        <div class="col-12">
            <NotificationEmailForm ref="form" />
        </div>
        <div class="col-lg-12 col-sm-12">
            <UpdateLog :dataLog="logs" />
        </div>
        <div class="col-12">
            <div class="d-flex justify-content-center justify-content-between flex-wrap">
                <div></div>
                <div>
                    <button class="custom-btn-save" @click="update">Atualizar</button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { mapActions, mapMutations, mapState } from "vuex";
import NotificationEmailForm from "../components/NotificationEmailForm.vue";
import UpdateLog from "../../../../components/UpdateLog.vue";
import { EventBus } from "@/eventBus";

export default {
    components: {
        NotificationEmailForm,
        UpdateLog
    },
    computed: {
        ...mapState('notificationEmail', ['notificationEmails', 'logs'])
    },
    methods: {
        ...mapActions('notificationEmail', ['updateNotificationEmail', 'getLogNotificationEmail']),
        ...mapMutations('notificationEmail', ['SET_NOTIFICATION_EMAIL_CURRENT']),
        getNotificationEmailCurrent() {
            let notificationEmailId = this.$route.query.id;

            let notificationEmail = this.notificationEmails.Items.find(a => a.Id === notificationEmailId);
            this.SET_NOTIFICATION_EMAIL_CURRENT(notificationEmail);
            this.getLogNotificationEmail(notificationEmailId);
            EventBus.$emit("loadNotificationEmail");
        },
        async update() {
            try {
                let form = this.$refs.form;
                if (!form.validate())
                    return;

                await this.updateNotificationEmail(form.getValue());
                this.$showSuccess("O cadastro foi atualizado com sucesso");
                this.$router.push({ name: 'NotificationEmail' });
            } catch (error) {
                this.$showError("Ocorreu um erro ao atualizar o cadastro");
            }
        }
    },
    mounted() {
        this.getNotificationEmailCurrent();
    }
}
</script>