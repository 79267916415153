<template>
  <auth-layout class="not-found-page">
    <div class="centered">
      <div class="row">
          <div class="col-sm-4 text-right">
            <img
            width="300"
            height="300"
            src="@/assets/images/unauthorized.png"
          />
          </div>
          <div class="col-sm-8 text-center text-middle" style="padding-top:7%">
            <h1><i class="not-found-icon nc-icon nc-puzzle-10"></i>Sem Autorização</h1>
            <p>
              Você não possui autorização para acessar esta página,<br/> favor entrar em contato com o administrador.              
            </p>        
            <p><a class="link-GoBack custom-btn-filter" @click="$router.go(-1)">Retornar</a></p>
          </div>
      </div>
    </div>
  </auth-layout>
</template>

<script>

export default {
 
};
</script>
<style lang="scss" scoped>
.not-found-page {
  .full-page > .content,
  .centered {
    min-height: calc(100vh - 160px);
  }
  .not-found-icon {
    margin-right: 10px;
  }
  .centered {
    h1,
    i {
      font-size: 50px;
    }
    p {
      font-size: 20px;
    }
    display: flex;
    padding-bottom: 150px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: white;
  }
  .link-GoBack{
    cursor: pointer;
    padding: 1%;
  }
  .link-GoBack:hover {
        background-color: #00ADB7;
        color: #fff;
    }
}
</style>
