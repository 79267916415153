<template>
    <el-dialog
        title="Push Notification"
        :visible.sync="dialogVisible"
        width="30%"
        :before-close="handleClose">
        <el-input placeholder="Titulo" v-model="notification.title"></el-input>
        <el-input class="mt-3" placeholder="Descrição" rows="1" type="textarea" maxlength="100" v-model="notification.description"></el-input>
        <span><small>Limite máximo de 100 caracteres.</small> </span>
        <span slot="footer" class="dialog-footer">
            <el-button @click="handleClose">Cancelar</el-button>
            <el-button type="primary" :loading="loading" @click="save">Enviar</el-button>
        </span>
    </el-dialog>
</template>
<script>
import Modal from '@/components/Modal'
import { mapActions } from 'vuex';

export default {
    components: [
        Modal
    ],
    components: { Modal },
    data() {
        return {
            dialogVisible: false,
            notification : {
                title : '',
                description : ''
            },
            loading : false
        }
    },
    methods: {
        ...mapActions(['sendPushNotification']),
        handleClose(){
            this.$router.push({name : 'Dashboard'});
        },
        save(){
            this.loading = true;
            if(this.$isNullOrEmpty(this.notification.description) && this.$isNullOrEmpty(this.notification.title))
            {
                this.$showError("Adicione o Titulo e Descrição!");
                return;
            }

            this.sendPushNotification(this.notification).then(res => {
                this.$showSuccess("Notificação enviada com sucesso.");
                this.handleClose();
            }).catch((err) => {
                console.info(err);
                this.$showError("Erro ao enviar.");
                this.loading = false;
            })

        }
    },
    mounted() {
        this.dialogVisible = true;
    },
    destroyed() {
        this.dialogVisible = false;
    },
}
</script>